import './styles.css';
import { motion } from 'framer-motion';

export default function App() {
  const mamaVariants = {
    init: { opacity: 1 },
    rest: { opacity: 1 },
    hover: { opacity: 1 },
  };
  const logoVariants = {
    init: { opacity: 1 },
    rest: { opacity: 1 },
    hover: { opacity: 1 },
  };
  const vendingVariants = {
    init: { opacity: 1 },
    rest: {
      opacity: 1,
      x: 0,
      y: 0,
      rotate: 0,
      scale: 1,
      transition: {
        duration: 0.8,
        type: 'tween',
        ease: 'easeInOut',
      },
    },
    hover: {
      opacity: 1,
      x: 20,
      y: 70,
      rotate: -3,
      scale: 0.8,
      transition: {
        duration: 0.8,
        type: 'tween',
        ease: 'easeInOut',
      },
    },
  };
  const smileVariants = {
    init: { opacity: 1 },
    rest: {
      opacity: 1,
      x: 0,
      y: 0,
      scale: 1,
      rotate: 0,
      transition: {
        duration: 1,
        type: 'spring',
        bounce: 0.75,
        damping: 3,
        stiffness: 80,
        ease: 'easeOut',
      },
    },
    hover: {
      opacity: 1,
      scale: 1,
      x: -50,
      y: -30,
      rotate: -30,
      transition: {
        duration: 0.4,
        type: 'spring',
        bounce: 0.5,
        damping: 5,
        stiffness: 100,
        ease: 'easeOut',
      },
    },
  };

  return (
    <div className="App">
      <motion.svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 655.6 177.9"
        xmlSpace="preserve"
        variants={mamaVariants}
        initial="init"
        animate="rest"
        whileHover="hover"
      >
        <motion.g id="logo" variants={logoVariants}>
          <path
            d="M128.5,77.5c8.1000061,9.3000031,12.1999969,21.3000031,12.1999969,35.3000031
				c0,12.5-4.1000061,24.1999969-11.6999969,33.5999908c-6.4000015,7.8999939-13.4000015,12-20.5999985,12
				C91.5,158.3999939,75.6999969,136,75.6999969,111.9999924c0-11.5999985,3.6999969-23.0999985,10.1999969-31.7999954
				C92.2999954,71.6999969,100.7999954,66.5,108.2999954,66.5C114.9000015,66.5,122.4000015,70.6999969,128.5,77.5 M101,77.9000015
				c-2.0999985,7.1999969-3.3000031,20-3.3000031,34.9000015C97.6999969,126.5,98.5,135.5,100.5,143.6999969
				c1.5,6.6999969,4,9.8000031,7.9000015,9.8000031c3.0999985,0,5.5-2.3999939,6.8000031-6.8000031
				c2.3000031-8.1000061,3.5-18.9000015,3.5-33.5c0-15.5-0.5999985-23.3000031-2.8000031-32.6999969
				C114.5,74.3000031,112,71.5,108.2000046,71.5C104.8000031,71.4000015,102.3000031,73.8000031,101,77.9000015"
          />
          <path
            d="M174,157.5l-14.6000061-63.8000031l-2.3999939,42c-0.1000061,1.8000031-0.3000031,3.8999939-0.3000031,4.3999939
				c0,6.8000031,2.1000061,10.3999939,8.1000061,14.8000031v0.5h-22v-0.5c6.1999969-4,7.8999939-7.6000061,8.8000031-19.1000061
				l2.8000031-50c0.1000061-1.3000031,0.1000061-2.5999985,0.1000061-3.1999969c0-6.6999969-1.8000031-10-7-12.5999985v-0.5
				h25.1999969l9.1000061,41.2999954l9.3000031-41.3000031h24.3000031V70
				c-4.3999939,3.0999985-6.1999969,6.4000015-6.1999969,12.0999985c0,0.5999985,0.1000061,3,0.3000031,4.9000015
				l3.1000061,52.6999969c0.6000061,8.1999969,2.3999939,12,7.5,15.1999969v0.5h-33v-0.5
				c5.3000031-4,6.8000031-7.1999969,6.8000031-13.8999939c0-1.1999969,0-2.1000061-0.3000031-5.3999939L191,93.6999969
				L177.1000061,157.5H174L174,157.5z"
          />
          <path
            d="M277,158.1000061l-38.1000061-65.6999969v45.9000092c0,8.3999939,2.1999969,12.5,8.6000061,16.6000061v0.5h-22.8999939
				v-0.5c6.6000061-4.5,8.5-9,8.5-19.1999969V89.3000031c0-8.8000031-1.8999939-13.3000031-8.5-19.1999969v-0.5999985h22.5
				l27.2999878,47V88.4000015c0-10.1999969-2.2999878-15.0999985-8.6000061-18.4000015v-0.5h23.1000061V70
				c-6.2000122,4.0999985-8.7999878,9.0999985-8.7999878,16.5999985v71.5000076H277L277,158.1000061z"
          />
          <path
            d="M326.5,155.3999939h-34.3999939v-0.5C297.8999939,152.0999908,300,147.6999969,300,138.2999878V86.5999985
				c0-9.0999985-1.7000122-13-7.5-16.5999985v-0.5h34V70c-5.7999878,4.0999985-7.2999878,7.5999985-7.2999878,16.5999985v51.7000046
				c0,9,1.5,12.5,7.2999878,16.6000061V155.3999939z"
          />
          <path
            d="M358.1000061,138.3000031c0,9,1.7000122,12.8000031,7.5,16.6000061v0.5h-34.3999939v-0.5
				c5.7999878-3.1000061,7.7000122-7.3000031,7.7000122-16.6000061V86.9000015
				c0-9.4000015-1.8999939-13.6999969-7.7000122-16.7000046v-0.5c9-1.5,18.5-2.3000031,27.6000061-2.3000031
				c12.7999878,0,20.5,1.9000015,27.3999939,6.6999969c6.7000122,4.5999985,10.3999939,12.1999969,10.3999939,21
				c0,11.1999969-6.2999878,20.5999985-16.7000122,25c-4.6000061,1.9000015-11.8999939,3.0999985-19.6000061,3.0999985h-2.2000122
				L358.1000061,138.3000031L358.1000061,138.3000031z M360.2999878,118.3000031c5.5,0,8.7999878-0.9000015,10.6000061-3.1999969
				c2.7000122-3.0999985,4.1000061-9.5,4.1000061-18.6999969c0-14.5999985-4.5-24.3000031-11.2000122-24.3000031
				c-4,0-5.7000122,2.4000015-5.7000122,8.1999969v38H360.2999878L360.2999878,118.3000031z"
          />
          <path
            d="M454.2000122,77.5c8.1000061,9.3000031,12.2000122,21.3000031,12.2000122,35.3000031
				c0,12.5-4.1000061,24.1999969-11.7000122,33.5999908c-6.3999939,7.8999939-13.3999939,12-20.6000061,12
				c-16.8999939,0-32.7000122-22.3999939-32.7000122-46.4000015c0-11.5999985,3.7000122-23.0999985,10.2000122-31.7999954
				C418,71.6999969,426.5,66.5,434,66.5C440.6000061,66.5,448.1000061,70.6999969,454.2000122,77.5 M426.7000122,77.9000015
				c-2.1000061,7.1999969-3.3999939,20-3.3999939,34.9000015c0,13.6999969,0.7999878,22.6999969,2.7999878,30.8999939
				c1.5,6.6999969,4,9.8000031,7.8999939,9.8000031c3.1000061,0,5.5-2.3999939,6.7999878-6.8000031
				c2.2999878-8.1000061,3.5-18.9000015,3.5-33.5c0-15.5-0.6000061-23.3000031-2.7999878-32.6999969
				c-1.3999939-6.1999969-3.8999939-9-7.7000122-9C430.5,71.4000015,428,73.8000031,426.7000122,77.9000015"
          />
          <path
            d="M520.5,160.1000061H520C518.0999756,156.4000092,516.2999878,155.5,510.6000061,155.5h-40.7999878V155
				c5.7999878-3.3000031,7.6000061-7.3000031,7.6000061-16.6999969V86.5999985
				c0-9.3000031-1.7999878-13.3000031-7.6000061-16.5999985v-0.5h34.5V70
				c-5.7000122,3.0999985-7.7000122,7.3000031-7.7000122,16.5999985V145c0,4.5,0.7999878,5.8999939,3.2999878,5.8999939
				c3.1000061,0,8.8999939-3.6000061,15.5000305-9.5C519.8000488,137.5,523,134.0999908,528,127.5999908l0.4000244,0.4000015
				L520.5,160.1000061z"
          />
          <path
            d="M579.5,160.1000061H579C577.0999756,156.4000092,575.2999878,155.5,569.5999756,155.5h-40.7999878V155
				c5.7999878-3.3000031,7.5999756-7.3000031,7.5999756-16.6999969V86.5999985
				c0-9.3000031-1.7999878-13.3000031-7.5999756-16.5999985v-0.5h34.5V70
				c-5.7000122,3.0999985-7.7000122,7.3000031-7.7000122,16.5999985V145c0,4.5,0.7999878,5.8999939,3.2999878,5.8999939
				c3.0999756,0,8.9000244-3.6000061,15.5-9.5c4.4000244-3.8999939,7.5999756-7.3000031,12.5999756-13.8000031l0.4000244,0.4000015
				L579.5,160.1000061z"
          />
          <path
            d="M643.4000244,77.5c8.0999756,9.3000031,12.2000122,21.3000031,12.2000122,35.3000031
				c0,12.5-4.0999756,24.1999969-11.7000122,33.5999908c-6.4000244,7.8999939-13.4000244,12-20.5999756,12
				c-16.9000244,0-32.7000122-22.3999939-32.7000122-46.4000015c0-11.5999985,3.7000122-23.0999985,10.2000122-31.7999954
				C607.2000732,71.6999969,615.7000732,66.5,623.2000732,66.5C629.9000244,66.5,637.4000244,70.6999969,643.4000244,77.5
				 M616,77.9000015c-2.0999756,7.1999969-3.4000244,20-3.4000244,34.9000015
				c0,13.6999969,0.7999878,22.6999969,2.7999878,30.8999939c1.5,6.6999969,4,9.8000031,7.9000244,9.8000031
				c3.0999756,0,5.5-2.3999939,6.7999878-6.8000031c2.2999878-8.1000061,3.5-18.9000015,3.5-33.5
				c0-15.5-0.7000122-23.3000031-2.7999878-32.6999969c-1.4000244-6.1999969-3.9000244-9-7.7000122-9
				C619.7000122,71.4000015,617.2999878,73.8000031,616,77.9000015"
          />
        </motion.g>
        <motion.g id="vending" variants={vendingVariants}>
          <path
            fill="#FF0000"
            d="M166.4966431,93.7016144c1.058197,0.8600769,1.4544373,1.5872955,1.4544373,2.7130814
				c0,0.7924881,0,0.7924881-2.9088745,10.9782028c-1.058197,3.7689514-1.7854156,6.2815857-1.9182739,7.0111389
				c-0.4638367,1.785408-0.7948151,2.9764633-0.8600769,3.3727036c-0.0652618,0.1981201-0.1328583,0.3962402-0.1328583,0.5943604
				c0,0.1328583,0.0675964,0.1981201,0.1328583,0.1981201c0.3309784,0,0.7272186-0.5943604,2.116394-3.3727036
				c0.1328583-0.2633896,1.6525574-3.2398529,2.3821106-4.5637665c2.379776-4.49617,2.9741364-5.820076,6.9435272-15.8053436
				c0.7272186-2.7783508,1.2563171-3.4403076,2.8436127-3.4403076c1.7854156,0,3.2398529,0.9929352,4.4309082,3.0417328
				c1.2563171,2.0511322,1.2563171,2.0511322,2.0511322,3.1745911c0.9906006,1.3891754,1.1887207,1.7877426,1.1887207,2.3145142
				c0,0.9929276-2.5778961,6.8806-4.4309082,10.055191c-2.9088745,5.0252686-8.4655609,12.8288803-13.0945892,18.3179779
				c-1.321579,1.5872955-2.5802307,3.1745911-3.8365479,4.6966248c-2.2469177,2.8436127-3.2398529,3.7036896-4.49617,3.7036896
				c-1.654892,0-3.5708313-1.1234589-5.8876648-3.5055695c-1.9835358-1.9835358-2.4450378-2.7783508-2.4450378-4.034668
				c0-0.5290985,0.0652618-1.1234589,0.1981201-1.8506775c0.263382-1.7854156,0.7272186-4.3003769,0.7272186-4.4309006
				c0.8600616-5.4238434,1.7201385-10.9805374,2.6454773-16.469635c0.5290985-3.7036896,0.9906006-6.151062,1.321579-7.2092514
				c0.7272186-2.2469254,1.1257935-3.7689514,1.1257935-4.3633118c0.1328583-2.2492523,0.263382-2.4473724,2.379776-2.9112091
				c1.058197-0.1981201,1.7854156-0.3309784,1.9182739-0.3962402c0.9253387-0.2657166,1.5220337-0.3962402,1.7854156-0.3962402
				C162.8582153,91.1237183,164.6459656,92.1143188,166.4966431,93.7016144z"
          />
          <path
            fill="#FF0000"
            d="M192.5832672,118.5692215c-1.1257935,0-1.1257935,0-2.8436127,3.3727112
				c-0.2657013,0.5290985-0.5966797,1.1910553-0.9276581,1.7877426c-0.5943604,1.058197-0.8577576,1.7854156-0.8577576,2.1816559
				s0.3286591,0.6619568,0.8577576,0.6619568h0.3985596h2.1816559c0.9929352,0,0.9929352,0,3.9018097-0.2657166l1.5872955,0.0652695
				c1.058197-0.2633896,1.058197-0.2633896,1.1910553-0.2633896c0.8600769,0,1.6525574,1.1910553,1.6525574,2.5126343
				c0,0.5966949,0,0.6619568-0.6619568,2.2492523c0,0.0652618-0.1305237,0.5290985-0.3286438,1.3891754
				c-0.1328583,0.6619568-1.2563171,2.379776-1.9182739,2.7783508c-0.3962402,0.3309784-0.5290985,0.3962402-2.713089,0.5943604
				c-0.5943604,0.0652618-1.7178192,0.263382-3.5055542,0.5290985c-0.3962555,0.0652618-1.5849762,0.1328583-3.5032349,0.1981201
				c-0.0675964,0-1.1910553,0.0675964-3.2421875,0.2657166h-0.8600769c-1.4544373,0-2.1816559-0.3985748-3.3727112-2.0511322
				c-2.2469177-3.1745911-3.7036896-6.2163239-3.7036896-7.9364777c0-0.9929276,0.0675964-1.3239059,1.1257935-3.5708237
				c0.9253387-1.9182739,0.9253387-1.9182739,1.8506775-4.2327881c0.1981201-0.5290985,0.1981201-0.5290985,1.2563171-2.382103
				c0.4638367-0.9253387,0.5966949-1.4544373,0.5966949-3.437973c0-1.4567719,0-1.4567719,0.4615021-2.116394
				c0.5290985-0.7948151,0.6619568-0.9253387,0.8600769-0.9929352h0.9253387
				c0.5290985-0.1981201,0.9929352-0.5943604,1.1910553-0.9906006c0.9929352-1.5896225,1.9835358-3.1745911,2.9764709-4.6966171
				c0.3309784-0.5290985,0.7947998-1.1257935,1.3891602-1.6525574c0.4638367-0.4638367,0.6619568-0.7295532,0.6619568-0.9929352
				c0-0.2657166,0-0.2657166-0.6619568-2.4473724c-0.0675812-0.1981201-0.0675812-0.5290985-0.0675812-0.9929276
				c0-2.3145142,0.3309784-3.3727112,1.2586365-3.8342133c1.1887207-0.5290985,6.5473022-1.1910553,10.0528717-1.1910553
				c2.9764709,0,6.0182037,0.3962402,7.4726257,0.9929352c1.1910553,0.5267639,1.5220337,1.058197,2.116394,3.2398529
				c0.0652618,0.5290909,0.3962402,1.3891678,0.7272186,2.3797684c0.2657166,0.7295532,0.3962402,1.3239136,0.3962402,1.7201538
				c0,1.1257935-0.9906006,2.3821106-1.8506775,2.3821106c-0.2657013,0-0.5943604-0.0675964-0.9253387-0.2657166
				c-0.5290985-0.263382-0.8600769-0.4638367-0.8600769-0.4638367c-0.132843-0.0652618-0.4638214-0.0652618-0.8600616-0.1305237
				c-0.3309784,0-0.8600769-0.0675964-1.3891754-0.1981201c-0.3962402-0.0675964-0.6619568-0.1328583-0.7272186-0.1328583
				l-2.3145142,0.1328583c-1.1910553,0-1.1910553,0-1.654892,0.7272186
				c-0.0652618,0.0652618-0.4615021,0.6596222-0.8600769,1.1910553c-1.7854156,2.5778961-2.9088745,4.3633041-2.9088745,4.8271408
				c0,0.3309784,0.3962402,0.4638367,2.116394,0.4638367c0.3962402,0,0.8600769,0.0652618,1.3891754,0.1305237l1.2563171,0.0675964
				c2.116394,0.0652618,2.7107544,0.5943604,2.7783508,2.2469177l0.0652618,1.1257935l0.1981201,1.5196991v0.3985672
				c0,0.5290985-0.1305237,0.7924881-0.5290985,1.1887283l-0.5943604,0.5966873l-0.5290985,0.7272186
				c-0.3309784,0.3962402-1.1910553,0.8600769-1.5872955,0.7924805l-2.5126343-0.3286438l-1.9858704,0.1981201l-2.0487976-0.0675964
				H192.5832672z"
          />
          <path
            fill="#FF0000"
            d="M219.3947906,122.4710312c0-0.8577423,0-1.5196991,0.0652618-1.8506775v-0.5290985
				c0-0.1981201,0-0.4638367-0.0652618-0.7272186c-0.1981201-1.7201538-0.3309784-2.8436127-0.3309784-2.9764709
				c0.0652618-0.9253387,0-1.1910477-0.2657166-1.1910477c-0.263382,0-0.3962402,0.3962402-0.4615021,0.9929276
				c-1.058197,4.034668-1.1257935,4.2980499-3.771286,12.3673782c-0.4615021,1.3891754-0.8600616,2.7783508-1.2563171,4.2327881
				c-0.6619415,2.5126343-1.2563019,3.437973-2.2492371,3.437973c-1.5196991,0-2.9764709-0.9906006-3.7036896-2.4473724
				c-0.3309784-0.7272186-0.5943604-1.2563171-0.7924805-1.3868408c0-0.0675964-0.5290985-0.4638367-1.3239136-0.9929352
				c-1.1887207-0.9253387-2.1816559-2.5126343-2.1816559-3.6384277c0-1.3891678,1.5220337-5.4215012,4.4985046-11.8382721
				c3.7689514-8.1998596,6.6125488-16.0057983,7.9341278-21.6930237c0.4638367-1.8506775,1.4567719-2.7107544,2.9764709-2.7107544
				c0.5966949,0,0.5966949,0,2.5149689,1.058197c0.1305237,0.0652618,0.8577423,0.3309784,1.9159393,0.7272186
				c2.9764709,1.1910553,3.0440674,1.3891754,3.2421875,15.6095581c0,0.7272186,0.1328583,1.058197,0.3309784,1.058197
				c0.263382,0,0.4615021-0.3985748,0.5943604-1.058197c0.2657166-1.4567642,2.9111938-8.6660233,4.8294678-13.1621857
				c1.1887207-2.6454926,1.9159393-3.7036896,2.8436127-3.6384277c0.9906006,0.0675964,2.116394,0.7948151,4.8924103,3.3074493
				c2.8459473,2.5802231,2.9112091,2.7107544,2.8459473,3.7689514c-0.0675964,0.9276657-0.0675964,0.9276657-4.1022644,8.5984192
				c-3.9018097,8.9294052-6.4797058,16.2039261-9.3908997,26.322052c-0.5290985,1.9182739-0.9929352,2.5149689-2.116394,2.5149689
				c-1.058197,0-1.321579-0.0675964-4.0999298-1.5872955c-2.8436127-1.5220337-3.4403076-2.2492523-3.4403076-4.1022644
				c0-0.263382,0.0675964-1.6525574,0.1328583-3.8342133c0.0652618-0.5290985,0-2.116394-0.0652618-3.7712784V122.4710312z"
          />
          <path
            fill="#FF0000"
            d="M244.4022522,97.4728928c0-2.9764633,0.6619568-4.1022568,2.5778961-4.5637589
				c1.5872955-0.4638367,7.7383423-1.058197,10.5167084-1.058197c4.0346375,0,6.8129883,0.9253387,9.1927795,3.1069946
				c3.1745911,2.9112015,5.5567017,8.2021866,5.5567017,12.1692581c0,2.5802231-1.9858704,8.4003067-4.7619019,13.6260223
				c-3.2421875,6.2815781-7.3421021,10.5143661-13.1621857,13.6236954
				c-2.8436127,1.5196991-4.9599915,2.3145142-6.1510468,2.3145142c-0.5943604,0-0.8600769-0.1981201-2.2492523-1.5872955
				c-0.7924805-0.7924805-1.8506775-1.5220337-2.2469177-1.4544373c-0.3985748,0.0652618-0.3985748,0.1305237-1.3239136,1.5196991
				c-0.3309784,0.5966949-0.9253387,0.9276733-1.5196991,0.9276733c-1.058197,0-1.7201538-0.4638367-3.9041443-2.713089
				c-2.1163788-2.1816559-2.7107544-3.1069946-2.7107544-4.1651917c0-1.654892,0.2657166-2.1839905,5.6219635-10.7148209
				c1.4544373-2.2492447,4.6290283-8.9294052,5.2257233-10.912941c0.3962402-1.2563095,0.7272186-2.1163864,0.7924805-2.382103
				c0.1981201-0.3962402,0.3309784-0.7272186,0.3309784-0.8577423c0-0.3309784-0.2657166-0.7948151-0.7272186-1.2586517
				c-0.9929352-0.9906006-1.1910553-1.4544373-1.1910553-2.5778961c0-0.3309784,0-0.6619568,0.0652618-1.1234589
				L244.4022522,97.4728928z M255.3804474,102.6310196c0,0.263382,0,0.263382,1.0582123,1.058197
				c0.1981201,0.1981201,0.3962402,0.5943604,0.3962402,0.9929352c0,2.3145065-4.034668,12.698349-6.5473022,16.7982788
				c-1.321579,2.1816559-1.3891754,2.3145142-1.3891754,2.6454849c0,0.2633896,0.1981201,0.3962479,0.6619568,0.3962479
				c1.6525574,0,3.8365326-2.116394,6.4144287-6.0182037c2.713089-4.2980499,5.4238434-10.5166931,5.7548065-13.2274475
				c0.1981201-1.9182739-1.4544373-3.2421875-4.0346375-3.2421875
				C256.3057861,102.0343246,255.3804474,102.3000412,255.3804474,102.6310196z"
          />
          <path
            fill="#FF0000"
            d="M280.2107544,98.2653809c0.2657166-0.594368,0.7272339-1.5872955,1.1910706-2.8436127
				c0.3962402-0.9253387,0.5943604-1.2563171,0.9253235-1.6525574c0.5291138-0.5966949,1.5220337-1.2586517,1.8530273-1.2586517
				c0.7272034,0,3.4379578,1.1910553,4.892395,2.1839905c1.4567871,0.9253387,2.4473877,2.5802231,2.4473877,3.9670715
				c0,0.8600693,0,0.8600693-3.3727112,8.2674484c-1.7201538,3.7036819-2.7107544,5.9529343-2.9764709,6.6801529
				c-1.058197,2.8436127-2.0487976,5.4238358-2.9764709,7.6730881c-0.3309937,0.8577423-0.3309937,0.8577423-1.5197144,4.6290207
				c-0.1981201,0.5943604-0.5966797,1.7201538-1.2563171,3.3727112c0,0.1981201-0.3309631,1.2563171-0.8600769,3.3074493
				c-0.5290833,2.1816559-0.6619568,2.4473724-1.6548767,2.8436127c-0.1305237,0-0.3962402,0.1328583-0.7272034,0.263382
				c-1.2563171,0.5966949-1.983551,0.7948151-2.7783508,0.7948151c-1.058197,0-1.058197,0-3.1745911-3.1093292
				c-0.1981201-0.263382-0.5943604-0.6619568-1.1234741-0.9906006c-1.19104-0.7948151-1.4544373-1.058197-1.4544373-1.9182739
				c0-0.4638367,0.1328735-1.058197,0.5291138-2.2492523c0.132843-0.3962402,0.5290833-1.3891754,0.8600769-2.5126343
				c0.5943604-1.7854156,1.1887207-3.5055618,1.3215637-3.9693985c0.2657166-0.5943604,1.2563171-3.0417328,2.7783508-6.6125641
				c0.8600769-1.9835281,1.7854309-4.3003769,2.7783508-7.0763931c1.2563171-3.2421799,2.116394-5.4238358,2.5779114-6.4167709
				L280.2107544,98.2653809z"
          />
          <path
            fill="#FF0000"
            d="M300.7896423,122.4710312c0-0.8577423,0-1.5196991,0.0652771-1.8506775v-0.5290985
				c0-0.1981201,0-0.4638367-0.0652771-0.7272186c-0.2004395-1.7201538-0.3309631-2.8436127-0.3309631-2.9764709
				c0.0652466-0.9253387,0-1.1910477-0.2657166-1.1910477c-0.2633972,0-0.3962402,0.3962402-0.4615173,0.9929276
				c-1.058197,4.034668-1.1257935,4.2980499-3.7712708,12.3673782c-0.4615173,1.3891754-0.8600769,2.7783508-1.2563171,4.2327881
				c-0.6619568,2.5126343-1.2563171,3.437973-2.2492371,3.437973c-1.5197144,0-2.9741516-0.9906006-3.7037048-2.4473724
				c-0.3286438-0.7272186-0.5943604-1.2563171-0.7924805-1.3868408c0-0.0675964-0.5290833-0.4638367-1.3239136-0.9929352
				c-1.1887207-0.9253387-2.1816406-2.5126343-2.1816406-3.6384277c0-1.3891678,1.5220337-5.4215012,4.4961548-11.8382721
				c3.7713013-8.1998596,6.6148987-16.0057983,7.9388123-21.6930237c0.4614868-1.8506775,1.4544373-2.7107544,2.9741211-2.7107544
				c0.5967102,0,0.5967102,0,2.5149841,1.058197c0.1305237,0.0652618,0.8600769,0.3309784,1.9182739,0.7272186
				c2.9741211,1.1910553,3.0417175,1.3891754,3.2398376,15.6095581c0,0.7272186,0.1328735,1.058197,0.3309937,1.058197
				c0.2633667,0,0.4614868-0.3985748,0.5943604-1.058197c0.2657166-1.4567642,2.9111938-8.6660233,4.8271484-13.1621857
				c1.19104-2.6454926,1.9182739-3.7036896,2.8459167-3.6384277c0.9906006,0.0675964,2.116394,0.7948151,4.8924255,3.3074493
				c2.8435974,2.5802231,2.9111938,2.7107544,2.8435974,3.7689514c-0.0652466,0.9276657-0.0652466,0.9276657-4.0999146,8.5984192
				c-3.901825,8.9294052-6.4820251,16.2039261-9.3908997,26.322052c-0.5291138,1.9182739-0.9929504,2.5149689-2.116394,2.5149689
				c-1.058197,0-1.3239136-0.0675964-4.0999451-1.5872955c-2.8435974-1.5220337-3.4403076-2.2492523-3.4403076-4.1022644
				c0-0.263382,0.0675964-1.6525574,0.1328735-3.8342133c0.0652466-0.5290985,0-2.116394-0.0652771-3.7712784V122.4710312z"
          />
          <path
            fill="#FF0000"
            d="M353.1447144,101.1089859c0,2.2492523-1.058197,4.1675262-3.1069946,5.7548141
				c-0.7947998,0.594368-1.5220337,0.9253387-2.1839905,0.9253387c-1.3891602,0-3.9670715-0.6619492-4.892395-1.2563095
				c-0.4638367-0.3309784-0.7947998-0.9929352-0.7947998-1.6525574c0-0.2657166,0-0.2657166,0.5966797-1.8530121
				c0.0652466-0.1981201,0.1305237-0.4615021,0.1305237-0.7272186c0-0.4638367-0.1981201-0.7924805-0.5290833-0.7924805
				c-0.925354,0-4.2980652,3.5708313-6.151062,6.4797058c-1.5873108,2.7130814-4.2327881,8.2674484-5.1581421,11.1110535
				c-0.5943604,1.654892-1.19104,4.6966248-1.19104,5.7548141c0,0.5290985,0.2657166,0.9253387,0.6619568,0.9253387
				c0.5290833,0,1.2563171-0.5943604,2.3145142-1.9835281c0.5290833-0.5966949,0.9929199-1.1910553,1.4567566-1.8530121
				c1.3868408-1.6525574,1.6525574-2.0487976,1.6525574-2.7107544c0-0.0652618-0.0675964-0.3309784-0.132843-0.7272186
				c-0.1981201-0.5966873-0.2633972-1.3891754-0.2633972-2.116394c0-1.9835281,0.2633972-2.4473648,1.9182739-2.9112015
				c1.7854309-0.5943604,3.041748-0.7272186,6.1487427-0.7924805c1.3891602-0.0675964,2.7130737-0.1328583,3.0440369-0.1328583
				c0.4615173-0.0652618,0.8577576-0.0652618,1.1887207-0.0652618c1.5220337,0,2.8436279,1.6525574,2.8436279,3.6360931
				c0,1.058197-0.3962402,2.3168411-1.1887207,3.7712784c-0.7272339,1.4544373-0.7948303,1.5196991-2.116394,1.5872955
				c-0.0675964,0-0.4638367,0.0652618-0.8600769,0.1328583c-0.3309937,0.0652618-0.5943604,0.1305237-0.8600769,0.1305237
				c-0.4638367,0.0675964-0.7924805,0.1981201-0.7924805,0.3309784s0.0652466,0.2657166,0.2633667,0.5290985
				c0.0652771,0.1981201,0.4638367,2.4473724,0.4638367,2.7107544c-0.0675964,0.5314255-0.2004395,0.6619568-2.2492371,3.3750381
				c-0.5291138,0.6596222-1.058197,1.2563171-1.5220337,1.6525574c-4.1651917,3.6384277-4.1651917,3.6384277-5.5543518,4.5637665
				c-2.0511475,1.321579-3.3074646,1.7854156-4.6966248,1.7854156c-2.116394,0-4.960022-1.321579-7.5402222-3.437973
				c-3.5708313-2.9112091-5.0252686-5.8224182-5.0252686-9.9876022c0-7.93647,3.8365173-17.4602356,9.5237427-23.7441559
				c4.2980652-4.761879,9.7218933-7.5378952,14.7471619-7.5378952
				C348.5809631,91.9837952,353.1447144,96.1489868,353.1447144,101.1089859z"
          />
          <path
            fill="#FF0000"
            d="M378.2990112,130.2093811c-1.7854004-1.4544373-2.0487976-1.7854156-2.0487976-2.9764709
				c0-0.5290909,0.4638367-3.7689438,0.5943604-4.4961624c0.7272339-2.9764709,0.7272339-2.9764709,0.9276733-4.3656464
				c0.1981201-1.2563171,0.7924805-4.0346603,1.7178345-8.4655685c0.0675964-0.3962402,0.132843-0.7272186,0.132843-0.9906006
				c0-0.2004471-0.132843-0.3309784-0.2657166-0.3309784c-0.1981201,0-0.4614868,0.3962402-0.7272034,1.058197l-0.7272339,1.5872955
				c-0.1305237,0.263382-0.6619568,1.9182739-1.3891602,4.2980499c-0.1981201,0.6619492-1.1234741,2.9764633-2.5779114,6.4144363
				c-1.3238831,3.4403-1.3891602,3.5731583-3.1745911,9.6566238c-0.6619263,2.4473724-1.6548767,3.8365479-2.6454773,3.8365479
				c-0.6619568,0-0.7272034,0-2.1839905-1.3891754c-0.3309631-0.3309784-0.7272034-0.5290985-1.1234436-0.5943604
				l-0.7272339-0.0675964h-0.7272034c-1.3239136,0-3.2421875-3.1745911-3.2421875-5.4891052
				c0-2.1816559,1.4567566-5.6219559,6.3491821-14.9476013c1.4567566-2.7760162,2.9111938-5.5543671,4.300354-8.3979721
				c2.2469482-4.4309082,5.2233887-9.5237656,7.6054993-12.9640732c0.8600769-1.2563171,1.7201538-1.7178192,3.1069946-1.7178192
				c1.1257935,0,1.1257935,0,4.8294983,2.5126343c2.4473572,1.7201538,2.9088745,2.4473724,2.9088745,5.1581268
				c0,3.5708237-0.0652771,4.3656387-0.3309937,6.4820328c-0.0652466,0.1981201-0.0652466,0.3309784-0.0652466,0.5290985
				s0.0652466,0.3309784,0.1981201,0.3309784c0.3309631,0,0.3309631,0,2.5149536-3.1093292
				c0.7272034-1.1234589,5.8853455-7.2745132,6.9435425-8.2674484c1.9835205-1.9835358,3.3727112-2.9764709,4.2327881-2.9764709
				c0.5943604,0,1.5220337,0.6619568,3.4379578,2.6454926c2.3168335,2.3145142,3.5055542,4.3003769,3.4403076,5.6219559
				c0,0.3962479-0.1981201,0.9929352-0.5966797,1.8530121c-3.4379883,9.8524094-4.4309082,12.8964767-5.6196289,17.5254974
				c-2.3145142,9.3909073-2.5802307,10.7800827-2.8459473,15.3438492c-0.0652771,1.2563171-0.4615173,1.6525574-2.116394,2.3145142
				c-1.4544373,0.5966949-2.2469177,0.9253387-2.2469177,0.9253387c-0.6619568,0-1.8530273-0.7272186-3.6384277-2.0487976
				c-1.8506775-1.4544373-2.9764709-2.9764709-2.9764709-4.1022644c0-0.9906006,1.058197-6.3491745,1.5872803-8.0017319
				c0.3309937-0.9906006,0.3309937-0.9906006,2.0511475-5.4238358c0.3309631-0.9253387,0.3309631-0.9253387,1.1234436-3.1745911
				c0.1981201-0.3962402,0.2657166-0.5290985,0.2657166-0.7272186s-0.132843-0.3309784-0.2657166-0.3309784
				c-0.1981201,0-0.3962402,0.1328583-0.5943604,0.3985748c-0.2657166,0.3962402-3.3074341,5.5543594-3.9017944,6.7454147
				c-1.1257935,2.1816559-1.1257935,2.1816559-2.9111938,5.5543671c-1.2563171,2.382103-1.6525574,3.3074417-2.0487976,4.6313553
				c-0.6619568,2.2469177-1.1257935,2.9088745-2.1839905,2.9088745c-0.9906006,0-1.7854309-0.4638367-3.041748-1.7201538
				L378.2990112,130.2093811z"
          />
          <path
            fill="#FF0000"
            d="M403.8612061,128.8878021c-0.1981201-0.2657166-0.3309631-0.6619568-0.3309631-1.058197
				c0-0.7948151,0.9253235-3.0417328,1.8506775-4.498497c0.8600769-1.321579,0.8600769-1.3891754,0.8600769-1.8506775
				c0-0.1981201,0-0.3985748-0.0652771-0.6619568c-0.1981201-0.8600769-0.1981201-0.8600769-0.2657166-1.7854156
				c0-0.3962402,0-0.3962402-0.1305237-1.3239059c0-0.3286514,0.3962402-1.058197,0.7924805-1.3868484
				c0.2657166-0.2004471,0.5291138-0.3309784,1.2563171-0.5314255c1.6549072-0.5943604,1.7201538-0.7272186,2.5149841-2.5126343
				c0.2633667-0.7272186,0.3962402-0.9253387,0.9906006-1.8506775c0.8600769-1.1257935,1.4544373-2.1839905,1.9182739-3.0440598
				c0.9253235-1.7178268,1.5872803-2.7760162,1.8506775-3.1745911l0.8600769-1.058197
				c0-0.0652618,0.2657166-0.4615021,0.5966797-1.1234589c0.6596375-1.2563171,4.9599915-6.5472946,8.4655762-10.3838425
				c1.8506775-2.0487976,2.2492371-2.3145142,3.0417175-2.3145142c0.8600769,0,0.9929504,0.0652695,1.9182739,0.8600769
				c0.2633972,0.263382,0.7924805,0.5943604,1.5220337,0.9253387c2.3145142,1.1257935,4.1651917,2.713089,4.1651917,3.5055695
				c0,0.4638367-0.0652466,0.5966873-1.058197,1.9835358c-0.132843,0.1981201-0.3309631,0.5290985-0.3309631,0.5290985
				c0,0.2657089,0,0.2657089,1.6548767,1.6548843c0.7924805,0.5943604,0.7924805,0.5943604,1.1234741,1.4544373
				c0.1981201,0.7272186,0.3309631,1.1234589,0.3309631,1.3239136c0,0.9906006-0.8600769,5.3562393-2.8435974,14.7471466
				c-1.1257935,5.2257233-1.2563171,5.7548218-2.9764709,11.5073013c-0.7272339,2.3821106-0.7272339,2.3821106-1.3891907,4.7618866
				c-0.5290833,1.9182739-1.2563171,2.713089-2.9764709,3.0440674l-0.3962402,0.0652618
				c-0.2657166,0.0652618-0.3962402,0.0652618-0.4638367,0.0652618c-0.4614868,0-1.3215637-0.3286438-2.1816406-0.8600769
				c-0.3309937-0.1981201-0.3309937-0.1981201-1.8530273-1.7854156c0-0.1305237-0.7924805-0.5290985-1.5872803-0.8577423
				c-0.9906006-0.3985748-1.3891602-0.9929352-1.3891602-2.0511322c0-0.3962402,0.2657166-1.058197,0.7295227-1.9835358
				c0.2633972-0.4638367,0.4615173-0.9276733,0.7272339-1.5220337c0.4615173-1.1234589,0.7272034-1.7201462,0.7272034-1.7854156
				c0.1328735-0.5966873,0.1328735-0.5966873,0.3962402-1.2563171l0.0675964-0.5290985
				c0-0.3309708-0.2657166-0.5966873-0.6619568-0.6619492c-0.5290833,0-2.0511169,0-2.116394,0.0652618
				c-0.7947998,0.3985672-0.7947998,0.3985672-1.4544373,0.5290985c-1.19104,0.1981201-1.2586365,0.1981201-1.9182434,1.7877426
				c-0.7295532,1.6525574-1.3239136,3.1745911-1.8530273,4.49617c-1.4544373,3.7689514-1.8506775,4.3656464-3.1745911,4.3656464
				c-0.3309631,0-0.3309631,0-2.7783508-0.7948151c-1.3891602-0.4615021-1.6525574-0.7924805-2.9741211-3.3727112
				c-0.2657166-0.4615021-0.4638367-0.8600769-0.5967102-0.9929352L403.8612061,128.8878021z M424.3631897,107.458168
				c-1.1910706,1.6548843-3.5055847,6.0857925-3.5055847,6.6148911c0,0.3309784,0.3309937,0.6596222,0.6619568,0.6596222
				c3.1745911,0,3.3074646-0.0652618,3.8365479-1.5849609c0.9906006-2.8459473,1.983551-6.8130112,1.983551-7.7383575
				c0-0.3309708-0.1981201-0.5966873-0.4638367-0.5966873C426.3467102,104.8126755,425.6195068,105.6074905,424.3631897,107.458168z
				"
          />
          <path
            fill="#FF0000"
            d="M459.1391602,122.5386276l0.5943604-0.1328583c0.6619568,0,1.9182739,2.7107544,1.9182739,4.0346603
				c0,0.6619568-0.7272339,2.7783508-1.3215942,3.7689514c-1.7201538,3.0440674-8.7965393,6.4820404-13.2950439,6.4820404
				c-2.0511169,0-3.6361084-0.8600769-6.283905-3.5708313c-3.4379883-3.4403076-3.901825-4.4309082-3.901825-8.1345978
				c0-7.3421097,3.375061-17.1968536,8.0040588-23.2150497c4.034668-5.2909775,11.3744507-9.9200058,15.6748352-9.9200058
				c1.9182739,0,5.1581116,1.5872955,7.3397827,3.5708313c1.2563171,1.1910553,1.7854004,2.4473648,1.7854004,4.2327805
				c0,3.5055695-1.1887207,7.5402298-2.5126343,8.5984268c-1.058197,0.7924805-4.5637512,2.0487976-5.8200684,2.0487976
				c-0.4638367,0-0.4638367,0-1.2563171-0.6596222c-0.9929199-0.8600769-1.5220337-1.2586441-1.5220337-1.2586441
				c-0.1305237-0.2633896-0.1981201-0.594368-0.1981201-0.9253387c0-0.7272186,0.2657166-1.6525574,0.8600769-2.7760162
				c0.8600769-1.654892,0.9929504-2.0511322,0.9929504-2.713089c0-0.4615021-0.1981201-0.7924805-0.5967102-0.7924805
				c-0.7272034,0-2.9764709,2.2469177-4.7618713,4.761879c-1.7178345,2.4450455-4.4961853,8.2674484-5.4891052,11.7706909
				c-0.6596375,2.1839828-1.058197,4.2327805-1.058197,5.7548141c0,1.6525574,0.6619568,2.6454849,1.9182739,2.6454849
				s6.3491821-2.1816559,7.6730957-3.3727036c0.2633667-0.1981201,0.5290833-0.3309784,0.5290833-0.3309784L459.1391602,122.5386276
				z"
          />
          <path
            fill="#FF0000"
            d="M468.3319397,134.5097656c-1.2563171-0.2657166-1.5220337-0.4638367-2.3797913-2.2492523
				c-0.9929199-2.116394-1.5896301-3.4403076-1.5896301-3.771286c0-0.4615021,0.7295532-2.643158,1.7201538-5.223381
				c0.6619568-1.7854156,1.1257935-2.9764709,1.2563171-3.3074493c0.5291138-1.5196991,0.8600769-2.5126343,1.058197-2.9088745
				c0.2657166-0.5966873,0.3985901-1.1257858,0.3985901-1.3239059s-0.2657166-2.116394-0.5967102-3.9670715
				c0.1328735-0.9929352,0.3962402-1.2586517,1.7201538-1.654892c1.5220337-0.5943604,1.5220337-0.6619568,1.9182739-1.8506775
				c0.0652771-0.1981201,2.9764709-7.2768478,3.1069946-7.6054916c1.9858704-4.2327881,1.9858704-4.2327881,2.5802307-5.556694
				c1.1910706-2.7783508,1.1910706-2.7783508,1.8529968-3.3727112c0.6596375-0.5290985,1.3891907-0.7924805,2.3797913-0.7924805
				c1.5220337,0,2.116394,0.3286438,4.3656311,2.643158c0.5291138,0.5966949,1.2563171,1.1910553,2.0511475,1.9858704
				c0.4614868,0.3962402,0.5943604,0.7272186,0.5943604,1.321579c0,0.9253387-1.1257935,3.3727036-3.7689514,8.2674484
				c-0.8600769,1.5872955-1.1910706,2.2492447-1.1910706,2.5126343c0,0.2004471,0.3309937,0.3985672,0.7272339,0.3985672
				c0.3309631,0,0.7924805-0.0675888,1.3215637-0.1328583l1.3891907-0.1328583
				c0.8600769-0.0652618,0.8600769-0.0652618,1.19104-0.9906006c0-0.2004471,0.4638367-1.2586441,0.9929504-2.2492447
				c0.3286438-0.5290985,0.9906006-1.9182739,1.6525574-3.4403076c1.3239136-2.9088745,3.0417175-6.3491745,3.9670715-8.0669937
				c0.6619568-1.1910553,1.1257935-1.5896301,1.9858704-1.5896301c1.1887207,0,3.0417175,1.4567719,4.4961548,3.4403076
				c0.6619568,0.9929352,2.116394,4.8947372,2.0511169,5.6219559c-0.0675964,0.4615021-0.2657166,1.1234589-0.7272034,1.9182739
				c-8.5331726,18.0545959-9.5913696,20.8982086-11.3091736,31.083931
				c-0.0675964,1.5872955-0.2657166,1.9835358-1.2563171,2.5802307c-0.7295532,0.3962402-1.5220337,0.6596222-2.116394,0.6596222
				c-0.9276733,0-0.9276733,0-2.6478271-1.1887207c-0.7272339-0.5290985-0.7272339-0.5290985-2.4450378-1.1910553
				c-1.7877502-0.7948151-2.7783508-1.9182739-2.7783508-3.2398529c0-0.5314331,0.132843-1.2586517,0.3962402-1.9858704
				c0.925354-2.9088745,0.925354-2.9088745,1.4544373-4.6290207c0.3985596-1.5220337,0.9276733-3.1093292,1.5220337-4.8271484
				c0.2657166-0.7948151,0.2657166-0.8600769,0.2657166-1.1910553s-0.20047-0.5966873-0.5967102-0.6619568
				c-0.132843-0.0652618-0.7272034,0.1328583-1.4544373,0.4638367c-0.3309631,0.1328583-0.7947998,0.1981201-1.3891602,0.263382
				c-0.925354,0.0675964-0.9929199,0.0675964-1.3891602,1.1257935c-0.1328735,0.5943604-0.4615173,1.5196991-0.8600769,2.5778961
				c-0.6596375,1.7854156-1.058197,3.0417328-1.2563171,3.7036896c-0.5943604,2.382103-1.2563171,4.761879-1.983551,7.0763931
				c-0.5943604,2.3168488-0.5943604,2.3168488-0.9929199,2.6454926c-0.3962402,0.3309784-0.9906006,0.5290985-1.5197144,0.5290985
				c-0.4638367,0-0.4638367,0-1.5872803-0.9906006c-0.20047-0.1981201-0.5967102-0.3309784-1.1257935-0.4638367
				L468.3319397,134.5097656z"
          />
          <path
            fill="#FF0000"
            d="M509.0934753,98.2653809c0.2657166-0.594368,0.7272034-1.5872955,1.19104-2.8436127
				c0.3962402-0.9253387,0.5943604-1.2563171,0.925354-1.6525574c0.5290833-0.5966949,1.5220032-1.2586517,1.8529968-1.2586517
				c0.7272339,0,3.4379883,1.1910553,4.892395,2.1839905c1.4567871,0.9253387,2.4473877,2.5802231,2.4473877,3.9670715
				c0,0.8600693,0,0.8600693-3.3727417,8.2674484c-1.7201538,3.7036819-2.7107544,5.9529343-2.9764404,6.6801529
				c-1.0581665,2.8436127-2.0488281,5.4238358-2.9764709,7.6730881c-0.3309631,0.8577423-0.3309631,0.8577423-1.5196838,4.6290207
				c-0.1981201,0.5943604-0.5967102,1.7201538-1.2563171,3.3727112c0,0.1981201-0.3309937,1.2563171-0.8600769,3.3074493
				c-0.5291138,2.1816559-0.6619568,2.4473724-1.6549072,2.8436127c-0.1305237,0-0.3962402,0.1328583-0.7272034,0.263382
				c-1.2563171,0.5966949-1.983551,0.7948151-2.7783508,0.7948151c-1.058197,0-1.058197,0-3.1745911-3.1093292
				c-0.1981201-0.263382-0.5943604-0.6619568-1.1234741-0.9906006c-1.19104-0.7948151-1.4544373-1.058197-1.4544373-1.9182739
				c0-0.4638367,0.1328735-1.058197,0.5291138-2.2492523c0.132843-0.3962402,0.5290833-1.3891754,0.8600769-2.5126343
				c0.5943604-1.7854156,1.1887207-3.5055618,1.3215637-3.9693985c0.2657166-0.5943604,1.2563171-3.0417328,2.7783508-6.6125641
				c0.8600769-1.9835281,1.7854309-4.3003769,2.7783508-7.0763931c1.2563171-3.2421799,2.116394-5.4238358,2.5779114-6.4167709
				L509.0934753,98.2653809z"
          />
          <path
            fill="#FF0000"
            d="M529.6746826,122.4710312c0-0.8577423,0-1.5196991,0.0652466-1.8506775v-0.5290985
				c0-0.1981201,0-0.4638367-0.0652466-0.7272186c-0.1981201-1.7201538-0.3309937-2.8436127-0.3309937-2.9764709
				c0.0652466-0.9253387,0-1.1910477-0.265686-1.1910477c-0.2634277,0-0.3962402,0.3962402-0.4615479,0.9929276
				c-1.0581665,4.034668-1.1257324,4.2980499-3.7712402,12.3673782c-0.4614868,1.3891754-0.8601074,2.7783508-1.2563477,4.2327881
				c-0.6619263,2.5126343-1.2562866,3.437973-2.2492065,3.437973c-1.5197144,0-2.9765015-0.9906006-3.7037354-2.4473724
				c-0.3309326-0.7272186-0.5943604-1.2563171-0.7924805-1.3868408c0-0.0675964-0.5290527-0.4638367-1.3239136-0.9929352
				c-1.1887207-0.9253387-2.1816406-2.5126343-2.1816406-3.6384277c0-1.3891678,1.5220337-5.4215012,4.4985352-11.8382721
				c3.7689209-8.1998596,6.6125488-16.0057983,7.9341431-21.6930237c0.4638062-1.8506775,1.4567261-2.7107544,2.9764404-2.7107544
				c0.5966797,0,0.5966797,0,2.5149536,1.058197c0.1305542,0.0652618,0.8577271,0.3309784,1.9159546,0.7272186
				c2.9764404,1.1910553,3.0440674,1.3891754,3.2421875,15.6095581c0,0.7272186,0.1328735,1.058197,0.3309937,1.058197
				c0.2633667,0,0.4614868-0.3985748,0.5943604-1.058197c0.265686-1.4567642,2.9111938-8.6660233,4.8294678-13.1621857
				c1.1887207-2.6454926,1.9159546-3.7036896,2.8435669-3.6384277c0.9906006,0.0675964,2.116394,0.7948151,4.8924561,3.3074493
				c2.8459473,2.5802231,2.9111938,2.7107544,2.8459473,3.7689514c-0.067627,0.9276657-0.067627,0.9276657-4.1022949,8.5984192
				c-3.9017944,8.9294052-6.4796753,16.2039261-9.3908691,26.322052c-0.5291138,1.9182739-0.992981,2.5149689-2.116394,2.5149689
				c-1.0582275,0-1.3215942-0.0675964-4.0999756-1.5872955c-2.8435669-1.5220337-3.4403076-2.2492523-3.4403076-4.1022644
				c0-0.263382,0.067627-1.6525574,0.1328735-3.8342133c0.0652466-0.5290985,0-2.116394-0.0652466-3.7712784V122.4710312z"
          />
          <path
            fill="#FF0000"
            d="M562.208374,118.5692215c-1.1257935,0-1.1257935,0-2.8436279,3.3727112
				c-0.265686,0.5290985-0.5966797,1.1910553-0.9276733,1.7877426c-0.5943604,1.058197-0.8600464,1.7854156-0.8600464,2.1816559
				s0.3309937,0.6619568,0.8600464,0.6619568h0.3986206h2.1816406c0.9929199,0,0.9929199,0,3.9017944-0.2657166l1.5872803,0.0652695
				c1.0582275-0.2633896,1.0582275-0.2633896,1.1911011-0.2633896c0.8600464,0,1.6525269,1.1910553,1.6525269,2.5126343
				c0,0.5966949,0,0.6619568-0.6596069,2.2492523c0,0.0652618-0.1328735,0.5290985-0.3309937,1.3891754
				c-0.1328735,0.6619568-1.258667,2.379776-1.9182739,2.7783508c-0.3985596,0.3309784-0.5291138,0.3962402-2.7130737,0.5943604
				c-0.5943604,0.0652618-1.7178345,0.263382-3.5032349,0.5290985c-0.3985596,0.0652618-1.5896606,0.1328583-3.5055542,0.1981201
				c-0.067627,0-1.1911011,0.0675964-3.2421875,0.2657166h-0.8601074c-1.4544067,0-2.1816406-0.3985748-3.3726807-2.0511322
				c-2.2492676-3.1745911-3.7036743-6.2163239-3.7036743-7.9364777c0-0.9929276,0.0675659-1.3239059,1.1257935-3.5708237
				c0.925293-1.9182739,0.925293-1.9182739,1.850647-4.2327881c0.1981201-0.5290985,0.1981201-0.5290985,1.2563477-2.382103
				c0.4638062-0.9253387,0.5966797-1.4544373,0.5966797-3.437973c0-1.4567719,0-1.4567719,0.4614868-2.116394
				c0.5291138-0.7948151,0.6619873-0.9253387,0.8601074-0.9929352h0.925293
				c0.5291138-0.1981201,0.992981-0.5943604,1.1911011-0.9906006c0.9929199-1.5896225,1.9835205-3.1745911,2.9764404-4.6966171
				c0.3309937-0.5290985,0.7924805-1.1257935,1.3891602-1.6525574c0.4614868-0.4638367,0.6619873-0.7295532,0.6619873-0.9929352
				c0-0.2657166,0-0.2657166-0.6619873-2.4473724c-0.0652466-0.1981201-0.0652466-0.5290985-0.0652466-0.9929276
				c0-2.3145142,0.3286133-3.3727112,1.2562866-3.8342133c1.1887207-0.5290985,6.5473022-1.1910553,10.0529175-1.1910553
				c2.9741211,0,6.0181885,0.3962402,7.4725952,0.9929352c1.19104,0.5267639,1.5220337,1.058197,2.116394,3.2398529
				c0.0652466,0.5290909,0.3962402,1.3891678,0.7272339,2.3797684c0.265686,0.7295532,0.3962402,1.3239136,0.3962402,1.7201538
				c0,1.1257935-0.9906006,2.3821106-1.850708,2.3821106c-0.265686,0-0.5966797-0.0675964-0.925293-0.2657166
				c-0.5291138-0.263382-0.8601074-0.4638367-0.8601074-0.4638367c-0.1328735-0.0652618-0.4638062-0.0652618-0.8600464-0.1305237
				c-0.3309937,0-0.8601074-0.0675964-1.3892212-0.1981201c-0.3962402-0.0675964-0.6619263-0.1328583-0.7271729-0.1328583
				l-2.3145142,0.1328583c-1.19104,0-1.19104,0-1.6549072,0.7272186c-0.0652466,0.0652618-0.4614868,0.6596222-0.8577271,1.1910553
				c-1.7877808,2.5778961-2.9111938,4.3633041-2.9111938,4.8271408c0,0.3309784,0.3962402,0.4638367,2.116394,0.4638367
				c0.3962402,0,0.8600464,0.0652618,1.3891602,0.1305237l1.2562866,0.0675964
				c2.116394,0.0652618,2.7107544,0.5943604,2.7783813,2.2469177l0.0652466,1.1257935l0.1981201,1.5196991v0.3985672
				c0,0.5290985-0.1328735,0.7924881-0.5291138,1.1887283l-0.5943604,0.5966873l-0.5290527,0.7272186
				c-0.3309937,0.3962402-1.1911011,0.8600769-1.5873413,0.7924805l-2.5126343-0.3286438l-1.9858398,0.1981201l-2.0488281-0.0675964
				H562.208374z"
          />
        </motion.g>
        <motion.g id="smile" variants={smileVariants}>
          <path
            fill="#F0FF00"
            d="M148.7666931,24.5604305c-0.1182404-0.1053104-0.2400818-0.2041569-0.3598175-0.3067627
				c-0.250824-0.2149029-0.503067-0.4268761-0.7602386-0.6302452c-0.1438141-0.1136131-0.2896271-0.2229061-0.4352112-0.333025
				c-0.2422943-0.1830807-0.4865112-0.3620663-0.7338257-0.5351543c-0.1521606-0.1064739-0.3048859-0.2114086-0.4587402-0.3141632
				c-0.2527771-0.1686172-0.5083618-0.3307343-0.7659149-0.4890938c-0.1493378-0.0919342-0.2977753-0.1853714-0.4486237-0.2738514
				c-0.2898254-0.1700439-0.5833282-0.3306942-0.8783569-0.4880772c-0.1204987-0.0642834-0.239212-0.1328125-0.3605347-0.1949158
				c-0.4172516-0.2137756-0.8386536-0.416357-1.2648773-0.6051483c-0.0723267-0.0320473-0.1461182-0.059248-0.2186737-0.09062
				c-0.3560181-0.1535873-0.7141266-0.3002625-1.0754089-0.4365692c-0.1502838-0.0566559-0.3019867-0.1073761-0.4531403-0.1609898
				c-0.2878723-0.1022663-0.5764008-0.2014523-0.8670959-0.2928619c-0.1696625-0.0533504-0.3400879-0.1029434-0.5106506-0.1525364
				c-0.2787781-0.081152-0.5583801-0.1575699-0.839325-0.2287292c-0.1732025-0.0439568-0.3464355-0.0870495-0.5203552-0.1272125
				c-0.2901154-0.0669518-0.5813293-0.1265755-0.8731689-0.1830807c-0.1624146-0.0313721-0.324234-0.0651474-0.4870605-0.0933266
				c-0.3445587-0.0596237-0.6904449-0.1091423-1.0368347-0.1542263c-0.1091919-0.0142021-0.217804-0.0331001-0.3271637-0.0458355
				c-0.4555817-0.0532761-0.9121399-0.0938511-1.3695679-0.1220665c-0.1140137-0.0070267-0.228241-0.0079269-0.3423767-0.0134125
				c-0.3440247-0.0165691-0.6881409-0.0288544-1.032547-0.0312214c-0.1658325-0.0011272-0.3314819,0.0031929-0.4973145,0.005373
				c-0.1912842,0.0024414-0.3825836-0.0007153-0.573822,0.0061226c-4.3349915,0.1549797-8.546196-1.451685-11.6134491-4.51894
				l-0.0000381-0.0000381l-3.0341187-3.0341167l-4.1156921-4.1156974l-3.8066483-3.8066418l-3.8066406,3.8066418
				l-5.6542053,5.6542087l-1.4956055,1.4956055L12.2096834,99.2897873l-1.4956818,1.4956818l-5.6542082,5.6542053
				l-3.8066421,3.8066406l3.8066421,3.8066483l4.1156969,4.1156921l3.0341177,3.0341187l0.0000372,0.0000381
				c3.067255,3.0672531,4.673995,7.2783737,4.5190916,11.6133041c-0.006876,0.1908875-0.0037575,0.3818665-0.0061626,0.5727234
				c-0.0021782,0.1662903-0.0065365,0.3323517-0.0054092,0.4986267c0.002367,0.3433685,0.0146904,0.6864624,0.0311451,1.02948
				c0.0055237,0.1153717,0.0064259,0.2307892,0.0135269,0.3460999c0.0282154,0.4571228,0.0688286,0.9134064,0.1220284,1.3687592
				c0.0127354,0.109375,0.0316334,0.2179871,0.0458355,0.3271637c0.045084,0.346405,0.0946026,0.692276,0.1541519,1.036911
				c0.0281773,0.1628265,0.0619545,0.324646,0.0933247,0.4870605c0.0565071,0.2918549,0.1162052,0.5829926,0.1830807,0.8731842
				c0.040163,0.1739044,0.0833321,0.3470764,0.1272144,0.52034c0.0712337,0.2808838,0.1476517,0.5604858,0.2288036,0.8392639
				c0.049593,0.1705627,0.0991859,0.3409882,0.1525364,0.5106506c0.0913353,0.2907562,0.1905956,0.5792236,0.2928619,0.8670959
				c0.0536137,0.1511383,0.1042595,0.3029175,0.1609898,0.453125c0.1362305,0.3613586,0.2829819,0.7194061,0.4365692,1.0754242
				c0.0312958,0.0726166,0.0584984,0.1464081,0.0905457,0.2187347c0.1887913,0.426239,0.3913708,0.8476257,0.6051464,1.2648926
				c0.0621052,0.1213074,0.1307087,0.2399597,0.1949921,0.3604431c0.157383,0.2950439,0.3179588,0.588623,0.4880772,0.8783569
				c0.0884037,0.1509247,0.1818428,0.2993622,0.273777,0.4487152c0.1584339,0.2574615,0.3205509,0.5130615,0.4891682,0.7658386
				c0.1026802,0.1539154,0.2076149,0.3066406,0.3141632,0.458725c0.1730881,0.2473297,0.3519993,0.4916077,0.5351543,0.7338257
				c0.1100445,0.1456604,0.2194118,0.2913971,0.333025,0.4352264c0.2033691,0.2571716,0.415266,0.509491,0.6302452,0.7602386
				c0.1026058,0.1197357,0.2014523,0.2415771,0.3066883,0.3598785c0.3249474,0.3649292,0.6595879,0.7242126,1.0095558,1.0741882
				c0.3499699,0.3499603,0.7092552,0.6846008,1.0741787,1.009552c0.118309,0.1052399,0.2401505,0.2040863,0.3598881,0.3066864
				c0.2507439,0.2149811,0.5030689,0.4268799,0.7602386,0.630249c0.1438198,0.1136017,0.2895546,0.2229767,0.435215,0.3330231
				c0.242218,0.1831512,0.4865017,0.3620605,0.7338276,0.5351562c0.1520863,0.1065521,0.3048096,0.2114868,0.4587364,0.3141632
				c0.2527733,0.1686096,0.5083656,0.3307343,0.7658367,0.4891663c0.1493416,0.0919342,0.2977829,0.1853638,0.4487038,0.2737732
				c0.2897434,0.1701202,0.5833187,0.3307037,0.8783607,0.4880829c0.120451,0.0642395,0.2391357,0.1328888,0.3604507,0.1949921
				c0.4172573,0.2137756,0.838686,0.4163818,1.2648869,0.6051483c0.0726223,0.0320435,0.1465626,0.0593872,0.2193737,0.0907288
				c0.3556042,0.1534729,0.7133884,0.3001099,1.0743656,0.436264c0.1505089,0.0567322,0.3025169,0.1074524,0.4538498,0.1612549
				c0.2878647,0.1022644,0.5764084,0.2014465,0.8671646,0.2927856c0.169445,0.0532837,0.3397141,0.1028748,0.5100212,0.1524963
				c0.2786598,0.0810394,0.5579948,0.1573486,0.8386841,0.2285461c0.1738014,0.0439606,0.3475266,0.0872345,0.5218925,0.1275482
				c0.2900429,0.0668793,0.5810661,0.1266174,0.8728371,0.1830444c0.1620789,0.0313416,0.323597,0.0649567,0.4862022,0.0930634
				c0.3441811,0.0595551,0.6895676,0.109024,1.0355148,0.1541138c0.1100464,0.0143127,0.2194138,0.0332184,0.3295708,0.0461426
				c0.4548645,0.0531616,0.9108582,0.0936279,1.3675652,0.1218719c0.1164322,0.0071716,0.2330132,0.008194,0.3494797,0.0137482
				c0.3421173,0.0163116,0.6841965,0.0286713,1.0266151,0.0310059c0.1666603,0.0011902,0.33321-0.0032806,0.4999504-0.0054474
				c0.1905174-0.0024414,0.3810387,0.0006714,0.5715218-0.0061646c4.3349571-0.1548615,8.5460854,1.4518738,11.6133385,4.5191193
				l0.6363297,0.6363373l3.0341187,3.0341187l4.1157341,4.1157379l3.8066025,3.8065948l3.8066483-3.8066406l5.6542053-5.6542053
				l1.4956818-1.4956818l85.5399551-85.5399628l1.4956207-1.4956055l5.6542053-5.6542053l3.8066406-3.8066483l-3.8066101-3.8066025
				l-4.1157379-4.1157341l-3.0341187-3.0341148l-0.636322-0.6363335c-3.0672607-3.0672531-4.6739197-7.2784538-4.5189819-11.6134872
				c0.0067291-0.1907463,0.0036469-0.3816795,0.0061188-0.5726128c0.002182-0.1662865,0.006546-0.3323479,0.0053406-0.4985619
				c-0.0023346-0.3434677-0.0146942-0.6865997-0.0311127-1.0297318c-0.0055237-0.1152267-0.0063782-0.2306061-0.0135193-0.3457985
				c-0.0282135-0.4569702-0.0687561-0.9131889-0.1219177-1.3683548c-0.0129242-0.110157-0.0318146-0.2195244-0.0461426-0.329567
				c-0.0450745-0.3459511-0.0945587-0.6913338-0.1541138-1.0355186c-0.0280914-0.1626053-0.0617981-0.3240471-0.0931244-0.4861259
				c-0.0564423-0.2917709-0.1161041-0.5828667-0.1830444-0.8728371c-0.0402374-0.1744385-0.0835266-0.3481636-0.1275635-0.5218887
				c-0.0711823-0.2806892-0.1474152-0.5601044-0.2285309-0.8386879c-0.0495605-0.1703835-0.0992279-0.3405762-0.15242-0.5100937
				c-0.0914154-0.2906837-0.1905975-0.5792236-0.2928009-0.8671646c-0.0537872-0.1513329-0.1045074-0.3033447-0.1612396-0.4538536
				c-0.1360931-0.3610497-0.2828064-0.7187614-0.4362793-1.0743637c-0.0314026-0.0727386-0.0586853-0.1467514-0.0908051-0.2192993
				c-0.1887512-0.4262009-0.3913727-0.8476295-0.6051483-1.2648849c-0.0621033-0.1213169-0.130661-0.2400761-0.1949158-0.360527
				c-0.1573792-0.295042-0.3180389-0.588541-0.4880829-0.8783607c-0.0884705-0.1508446-0.1819153-0.2992859-0.2738495-0.4486294
				c-0.1583557-0.2575455-0.3204651-0.5131378-0.48909-0.7659111c-0.1027527-0.1538525-0.2076874-0.3065758-0.3141632-0.4587364
				c-0.1730804-0.2473259-0.352066-0.4915352-0.5351562-0.7338276c-0.1101227-0.1455841-0.2194061-0.2913952-0.3330231-0.435215
				c-0.2033691-0.2571716-0.4153442-0.5094185-0.630249-0.7602386c-0.1026001-0.1197376-0.2014465-0.2415791-0.3067627-0.3598137
				c-0.3248596-0.3649979-0.6595001-0.7242832-1.0094757-1.0742531
				C149.4909668,25.219944,149.1316833,24.8853035,148.7666931,24.5604305z"
          />
          <g>
            <g>
              <path
                d="M93.3610916,107.1062775c0.4061813,0.4419479,0.8151474,0.8813934,1.2171555,1.3271103
						c0.1563873,0.1733932,0.2992096,0.3592606,0.4442062,0.5426025c0.0966339,0.1221771,0.1849823,0.2508698,0.2792206,0.3749771
						c0.122551,0.1613998,0.2475357,0.3206406,0.3836136,0.4714279c0.1463013,0.1621246,0.2705688,0.3421478,0.3999405,0.5179214
						c0.18013,0.2447433,0.3667145,0.4848022,0.543663,0.7317886c0.1041946,0.1454468,0.1924057,0.3022385,0.2927475,0.4505844
						c0.0872192,0.128952,0.1761703,0.257431,0.2738419,0.3784561c0.1177063,0.1458511,0.2306366,0.2945328,0.3289413,0.4539795
						c0.1764297,0.2861404,0.3873749,0.5467606,0.5986938,0.8067627c0.1720123,0.2116394,0.3439407,0.4237442,0.53862,0.61586
						c0.0387497,0.0382385,0.0735855,0.0806351,0.108429,0.1226044c0.1842041,0.2219086,0.3692093,0.4431763,0.5511856,0.6669083
						c0.1229172,0.151123,0.2429504,0.3046722,0.3613586,0.4593811c0.273468,0.3573074,0.6035767,0.650856,0.9904251,0.8795624
						c0.2963104,0.1751785,0.6125565,0.2178574,0.946579,0.1302032c0.1518173-0.0398331,0.2976913-0.0944748,0.4319458-0.1749649
						c0.2074432-0.1243744,0.4227905-0.2332916,0.6385574-0.3419037c0.2368698-0.1192398,0.4633408-0.2545395,0.6774902-0.4129868
						c0.3068161-0.2269974,0.6251984-0.438324,0.9334641-0.6634216c0.2018204-0.1473618,0.4117279-0.2800674,0.6268921-0.406723
						c0.2623825-0.1544342,0.499115-0.3464127,0.7359848-0.5369949c0.194313-0.1563263,0.3871918-0.3144226,0.580719-0.4717255
						c0.036293-0.0294952,0.0753326-0.0561829,0.1085739-0.0887909c0.2347641-0.2303314,0.4992828-0.4232407,0.7694778-0.6082458
						c0.1360321-0.0931473,0.2616882-0.1977997,0.3748703-0.3159332c0.2489395-0.2598114,0.5318222-0.4808426,0.8026199-0.7157211
						c0.0765457-0.0663986,0.1547546-0.1310425,0.2287216-0.2002029c0.1310349-0.1225281,0.2625732-0.2438126,0.4077759-0.3501892
						c0.0688248-0.0504227,0.1306534-0.111145,0.1917496-0.1711731c0.0833817-0.0819397,0.1641769-0.1665955,0.2440796-0.2519608
						c0.2770691-0.2959976,0.5521317-0.5938873,0.8302536-0.8888931c0.1502075-0.159317,0.2761459-0.3378983,0.4125137-0.5081177
						c0.2241211-0.2797394,0.4447784-0.5622711,0.6645279-0.845459c0.0954666-0.123024,0.1880112-0.2480698,0.2722931-0.3797607
						c0.134346-0.2098846,0.2779617-0.4138184,0.4174805-0.6203842c0.0610657-0.0903931,0.1223526-0.1806488,0.1824265-0.271698
						c0.1072311-0.1625671,0.2110367-0.327446,0.3212662-0.4879379c0.0751801-0.1094589,0.1420135-0.2232666,0.2071686-0.3386917
						c0.0842133-0.1491928,0.1711197-0.2970047,0.2612305-0.4427032c0.3895264-0.6298828,0.7452774-1.2786179,1.0869522-1.935379
						c0.0790329-0.1519165,0.1661606-0.2996368,0.2497635-0.449173c0.0798264-0.1427689,0.1590805-0.2858658,0.2401428-0.4279327
						c0.0617142-0.1081619,0.1233292-0.2165222,0.1895142-0.3219528c0.103714-0.1652374,0.2012787-0.3331604,0.2769928-0.5136719
						c0.0451508-0.1076736,0.0986099-0.2118988,0.1490097-0.3173523c0.0941391-0.1969452,0.1828384-0.3961639,0.2567596-0.6017532
						c0.0818787-0.2277527,0.1817017-0.4476776,0.2969055-0.6603165c0.1004257-0.1853561,0.1858826-0.3770981,0.2636032-0.5728912
						c0.0804749-0.2027435,0.1660919-0.40345,0.2495041-0.6050186c0.1520309-0.3673859,0.2957458-0.7376099,0.4051132-1.1205902
						c0.0984344-0.344696,0.2114029-0.6848679,0.3217239-1.0259857c0.1773834-0.5484848,0.3556137-1.0972366,0.4798279-1.6615982
						c0.0603027-0.2739563,0.1360092-0.544487,0.1998825-0.8177032c0.0727539-0.3111496,0.1402054-0.6235352,0.2097092-0.9354401
						c0.0305099-0.1369553,0.0590668-0.2743607,0.0908432-0.411026c0.0459595-0.1976624,0.0791397-0.3973236,0.1057739-0.5984879
						c0.0276184-0.2086029,0.0587387-0.4168549,0.0942535-0.6242523c0.0329742-0.1925583,0.0571671-0.3853226,0.0592194-0.5809479
						c0.0020447-0.1951294,0.0227127-0.3888092,0.0473022-0.5824356c0.0225754-0.1777039,0.0348892-0.35672,0.0511398-0.5352173
						c0.0148239-0.163002,0.0214539-0.3271408,0.0447311-0.4889069c0.0600128-0.4172287,0.0920334-0.8369293,0.1217728-1.256897
						c0.040802-0.576004,0.0489807-1.1525879,0.0327911-1.7299042c-0.0078506-0.2804031-0.0009918-0.561203-0.0019913-0.8418198
						c-0.0003281-0.0935059-0.0001144-0.1873474-0.0075455-0.2804413c-0.0247879-0.3108673-0.0513306-0.6216049-0.0803528-0.932106
						c-0.0188828-0.2020264-0.0352249-0.4041367-0.0303116-0.607048c0.0104141-0.4304504-0.0302277-0.8565826-0.1003494-1.280632
						c-0.0381622-0.230751-0.0743866-0.4618225-0.1116943-0.6927032c-0.0074692-0.0461807-0.0146561-0.092453-0.0241241-0.138237
						c-0.137352-0.6640778-0.2581635-1.3315735-0.40979-1.9926987c-0.0313492-0.1367188-0.0691528-0.2719421-0.1016006-0.4084244
						c-0.0504684-0.2122879-0.0978317-0.4253082-0.1485672-0.6375351c-0.0325928-0.1363449-0.0656967-0.2727127-0.1042328-0.4074631
						c-0.0578461-0.2022476-0.124382-0.4020691-0.1804733-0.6047821c-0.0791397-0.285965-0.1732254-0.5659943-0.2950134-0.8369217
						c-0.1567078-0.3485794-0.2932205-0.7046432-0.4007416-1.0720825c-0.0766983-0.2621231-0.1811523-0.5141602-0.3082199-0.7566147
						c-0.1122818-0.2142487-0.2043457-0.4372101-0.2876434-0.6644592c-0.2695465-0.7353745-0.2694473-0.7356644-0.6368408-1.4282227
						c-0.0911636-0.1718521-0.192009-0.3388901-0.2948761-0.5041199c-0.1278839-0.2053833-0.2461929-0.4150925-0.3433228-0.6371002
						c-0.0624008-0.1426315-0.1322327-0.2821655-0.2022247-0.4213257c-0.161499-0.3211136-0.3536453-0.6230927-0.5722275-0.9084244
						c-0.0473633-0.061821-0.0909958-0.1264954-0.1365814-0.189682c-0.2656784-0.3683929-0.6263275-0.5506134-1.0798645-0.5489349
						c-0.2506638,0.0009308-0.4972153,0.0363235-0.7410812,0.0928116c-0.2201691,0.0510025-0.4408035,0.1000137-0.6606903,0.1522217
						c-0.106102,0.0251846-0.2110901,0.0550919-0.3162918,0.0839386c-0.1352997,0.037117-0.2698135,0.0771713-0.4055252,0.1126556
						c-0.3178558,0.0830917-0.64048,0.1352081-0.9695358,0.1440201c-0.1324997,0.0035477-0.2649155,0.0107574-0.3974228,0.0128403
						c-0.0856552,0.0013428-0.1720581,0.0023804-0.2570267-0.006691c-0.4962082-0.0529251-0.9926453-0.1062927-1.4770737-0.2330246
						c-0.1279373-0.0334702-0.2541809-0.0747147-0.3788681-0.1189423c-0.1762009-0.0625229-0.3499832-0.1318359-0.5252686-0.1969681
						c-0.2193909-0.0815277-0.4337006-0.1741104-0.64077-0.2836075c-0.1928024-0.1019592-0.3867493-0.2017517-0.5805817-0.3017654
						c-0.4523773-0.2334213-0.8143158-0.57164-1.1225967-0.9711761c-0.0808029-0.1047287-0.1633835-0.2082443-0.2269974-0.3257675
						c-0.108902-0.2011948-0.1823502-0.4128952-0.2085419-0.6406021c-0.0290985-0.2531128-0.0014954-0.4985657,0.1040268-0.7318878
						c0.0545349-0.120575,0.1135712-0.2393036,0.174942-0.3565826c0.0872879-0.1668015,0.1988602-0.3164139,0.3330307-0.4488831
						c0.0554886-0.0547791,0.1102295-0.110321,0.1657715-0.1650543c0.1704483-0.1679535,0.3734283-0.2779694,0.6030197-0.342865
						c0.2430878-0.068718,0.4902191-0.0936737,0.7409668-0.0677261c0.3264313,0.0337524,0.6475601,0.0943222,0.9586334,0.2030106
						c0.3015594,0.1053696,0.6040192,0.2081604,0.9067535,0.3100891c0.0811234,0.0273056,0.1644363,0.0482941,0.2472229,0.0703735
						c0.08284,0.0220947,0.1673508,0.0383911,0.2492676,0.0633469c0.4053726,0.1235046,0.8231583,0.1777267,1.2429352,0.2107697
						c0.455307,0.0358505,0.9016647-0.0157318,1.3349915-0.1726379c0.2719421-0.098465,0.5317993-0.2225342,0.792717-0.3448257
						c0.1635361-0.0766525,0.2963257-0.2007294,0.4418793-0.3049088c0.0759964-0.05439,0.1496735-0.1126251,0.220047-0.1740952
						c0.0643387-0.0561981,0.1286926-0.1142807,0.1826553-0.1800461c0.1136398-0.138504,0.2228241-0.2808456,0.3296432-0.4247208
						c0.2884216-0.3884735,0.4246597-0.8287163,0.4350815-1.3096008c0.0069733-0.3220444-0.037117-0.6397972-0.1258774-0.9474411
						c-0.1210861-0.4197731-0.2061462-0.8481674-0.3231125-1.2687988c-0.0964966-0.347023-0.1308823-0.7045708-0.1491241-1.0634651
						c-0.0023727-0.0466652-0.0009918-0.0935593-0.0004196-0.1403389c0.0045547-0.3700409,0.1176224-0.7043571,0.3318787-1.0051613
						c0.0226135-0.0317459,0.0481033-0.0615311,0.0694885-0.0940514c0.1143188-0.1737976,0.2716675-0.3003311,0.4501114-0.4004745
						c0.1978683-0.1110458,0.408783-0.1922874,0.6285858-0.2492599c0.1140213-0.0295601,0.2302246-0.039238,0.347908-0.0329399
						c0.1329422,0.0071182,0.2639847,0.0269203,0.3913574,0.0651512c0.2625656,0.0788002,0.5099258,0.1932907,0.7458878,0.3316498
						c0.1630325,0.0955963,0.2972946,0.2241364,0.4174576,0.3705025c0.2031479,0.247448,0.3834915,0.5107002,0.562561,0.775444
						c0.1136169,0.1679916,0.2257156,0.3357544,0.314003,0.5192909c0.139328,0.2896538,0.2431488,0.5912971,0.30867,0.9051056
						c0.0637054,0.3051567,0.1186752,0.6121864,0.1743622,0.9189606c0.0166702,0.0918465,0.0265884,0.1853409,0.0331039,0.2785301
						c0.0157394,0.2255592,0.0291367,0.4513092,0.0404358,0.6771355c0.0046616,0.0933037,0.0046234,0.1871109,0.0013733,0.2804985
						c-0.0209656,0.601593-0.0909729,1.1974297-0.2317352,1.7830009c-0.0582123,0.242157-0.1283722,0.4822922-0.2080841,0.718277
						c-0.2277222,0.6741257-0.2115631,1.3484955-0.0198135,2.025383c0.0384674,0.1358109,0.1007233,0.2617874,0.1746902,0.381485
						c0.208847,0.3379898,0.4197159,0.674736,0.6284027,1.0128326c0.0777054,0.1259003,0.1579056,0.2508926,0.2260284,0.3819733
						c0.226326,0.4355392,0.4479523,0.8735123,0.6706772,1.3109131c0.0388718,0.0763626,0.0752945,0.1540146,0.1110458,0.2319031
						c0.104126,0.2268677,0.2159042,0.449379,0.3573532,0.6560135c0.0351334,0.0513306,0.0648956,0.1064758,0.0950928,0.1609955
						c0.1698532,0.3066711,0.3386154,0.6139526,0.5086899,0.9205017c0.1060867,0.1912079,0.198349,0.3887177,0.2716217,0.5946732
						c0.091507,0.2571793,0.199234,0.5074844,0.3080521,0.7576981c0.1616211,0.3716507,0.3062592,0.750061,0.4313965,1.1359329
						c0.1322021,0.4076767,0.2704697,0.813385,0.4038696,1.2206726c0.0363464,0.1109772,0.0655823,0.2243195,0.09655,0.3369904
						c0.0516357,0.1878433,0.0964355,0.3777466,0.1539154,0.5637512c0.2466888,0.7982941,0.433136,1.6104431,0.5710678,2.4341736
						c0.0257339,0.1537247,0.0580826,0.3063278,0.0867386,0.4595642c0.031517,0.1685562,0.0622864,0.3372498,0.0933609,0.5058823
						c0.0367126,0.1993103,0.0721283,0.3988647,0.1103439,0.5978928c0.0764465,0.3981705,0.1502304,0.7962875,0.1852951,1.2012863
						c0.0268784,0.310524,0.0707397,0.6196213,0.1087646,0.9291382c0.0266495,0.2168655,0.0499649,0.4340363,0.0478821,0.6527252
						c-0.003273,0.343605,0.022728,0.6856766,0.0489273,1.0277252c0.0279999,0.3653717,0.0664749,0.7301025,0.0864944,1.095871
						c0.0213089,0.3890762,0.0290985,0.7789917,0.0367737,1.1686554c0.0022812,0.1163712-0.010498,0.2337875-0.0253296,0.3495789
						c-0.0297775,0.2324219-0.0466537,0.4653549-0.0466843,0.6996078c-0.0000305,0.3040619-0.0008698,0.6081161-0.000267,0.912178
						c0.0007172,0.359024-0.0154037,0.7171555-0.0576935,1.0737534c-0.0220566,0.1859894-0.0390854,0.3721313-0.041214,0.5595322
						c-0.0033798,0.2972717-0.0416565,0.5905075-0.0981369,0.882103c-0.0340652,0.1759262-0.0635834,0.3529053-0.0889206,0.530304
						c-0.0408249,0.2857208-0.0881958,0.5701065-0.1524506,0.8515396c-0.0816422,0.3574753-0.1457062,0.7179871-0.1945801,1.0814514
						c-0.0270004,0.2008438-0.0579987,0.4013138-0.0940475,0.6007156c-0.0262909,0.1455307-0.0588074,0.2903595-0.0971222,0.4332047
						c-0.1393127,0.5194168-0.2819748,1.0379257-0.4238968,1.556633c-0.0678711,0.2480774-0.1389999,0.495285-0.2047501,0.7439194
						c-0.0557785,0.2109222-0.110611,0.422287-0.1576538,0.6352768c-0.111351,0.5039825-0.2609482,0.9943924-0.4855499,1.4608688
						c-0.1251602,0.259964-0.2303391,0.5284348-0.3159256,0.8044281c-0.0531921,0.1715088-0.1213379,0.3372116-0.2061462,0.4957809
						c-0.136261,0.2547607-0.2496948,0.5195312-0.3482208,0.7909393c-0.071846,0.1979218-0.1542435,0.3911667-0.2502289,0.5789185
						c-0.0531082,0.1038895-0.1004333,0.2112198-0.1431427,0.3198395c-0.0913696,0.2323837-0.1996689,0.4567032-0.3095322,0.6806488
						c-0.250824,0.5112839-0.5442581,0.9985046-0.8339462,1.4878922c-0.0436554,0.0737381-0.0905991,0.145546-0.1341934,0.2193146
						c-0.1031189,0.1744843-0.2086868,0.3487015-0.2761154,0.540451c-0.1355591,0.3854523-0.3332748,0.7372742-0.5601807,1.0747452
						c-0.2045059,0.3041306-0.3916473,0.6185303-0.5582504,0.9453583c-0.067131,0.131691-0.1465302,0.2571106-0.2204056,0.3853531
						c-0.1088562,0.1889725-0.2209091,0.3761978-0.325882,0.5673065c-0.1505508,0.2741089-0.3323593,0.5269699-0.5159683,0.7789078
						c-0.0504608,0.0692444-0.0994263,0.1396103-0.1508865,0.2080994c-0.1076279,0.1432495-0.2118988,0.288681-0.3290329,0.4251175
						c-0.1267471,0.1476288-0.2445221,0.3034744-0.3591766,0.4608612c-0.2023697,0.2777557-0.4268646,0.5366592-0.6563416,0.7917099
						c-0.1564484,0.1738739-0.3119888,0.3485641-0.4684753,0.5224152c-0.2348938,0.2609634-0.4764328,0.5150986-0.7472916,0.7399368
						c-0.1261978,0.1047516-0.2392654,0.2231369-0.3450851,0.3485489c-0.1411362,0.1672668-0.2965317,0.3188934-0.4692688,0.4547958
						c-0.1899948,0.1494751-0.364151,0.3177948-0.5286026,0.4954376c-0.1909103,0.2062073-0.3946381,0.3981018-0.6087341,0.5807114
						c-0.2196655,0.1873627-0.4427414,0.3693848-0.6767731,0.5382385c-0.0315399,0.0227661-0.0609512,0.0486145-0.0903702,0.074173
						c-0.1942062,0.1687241-0.3865967,0.3395844-0.5826416,0.5061417c-0.1662903,0.1412888-0.3372421,0.2770767-0.5050201,0.4166336
						c-0.0779114,0.0647888-0.1551895,0.1305923-0.2292862,0.1996613c-0.279953,0.2609406-0.5858917,0.4900436-0.887619,0.7240448
						c-0.3386688,0.2626495-0.6829147,0.5180969-1.022934,0.7790222c-0.2474747,0.1899109-0.508255,0.3607254-0.7624817,0.5409317
						c-0.5728531,0.4060745-1.1807632,0.7560196-1.7792664,1.1211395c-0.1595993,0.0973663-0.3173904,0.1977081-0.4752808,0.2978439
						c-0.1713486,0.1086731-0.3475342,0.2077637-0.5348663,0.2868729c-0.1651611,0.0697479-0.3246536,0.1508484-0.4812012,0.2388458
						c-0.1356049,0.0762024-0.2764587,0.1436768-0.4178543,0.208786c-0.155838,0.0717468-0.3102417,0.145752-0.4586487,0.2319183
						c-0.1418915,0.0823669-0.2907944,0.1491089-0.4440918,0.2076416c-0.1163101,0.0444031-0.2316971,0.0927048-0.3431702,0.147995
						c-0.4332123,0.2148819-0.8786087,0.4016647-1.3276443,0.5799179c-0.3694687,0.1466599-0.7377701,0.2954102-1.0922089,0.4765167
						c-0.1458664,0.0745239-0.298317,0.1337891-0.4530869,0.1879196c-0.1322556,0.0462494-0.2635269,0.0964279-0.3917389,0.1528091
						c-0.1358719,0.0597382-0.2737656,0.1120987-0.4159622,0.1542969c-0.291481,0.0865097-0.5796738,0.1827087-0.862442,0.2949371
						c-0.1669083,0.0662384-0.3388367,0.116127-0.5143738,0.154892c-0.1597824,0.0352859-0.3181839,0.0768356-0.4773178,0.1151276
						c-0.1136703,0.0273438-0.2274017,0.0544281-0.3413391,0.0806503c-0.2734375,0.0629196-0.5483246,0.1200409-0.8202515,0.1888962
						c-0.5673599,0.1436691-1.1405487,0.2563019-1.7187958,0.3456421c-0.2078552,0.0321274-0.414505,0.0735474-0.6202087,0.117691
						c-0.1988907,0.0426865-0.3986588,0.0726013-0.6020966,0.0776215c-0.2577133,0.0063705-0.5133667,0.0309219-0.7665558,0.081665
						c-0.0838623,0.0168076-0.1697235,0.0243149-0.2549438,0.0335007c-0.2869644,0.0309143-0.5729446,0.0662231-0.8523788,0.1430817
						c-0.1740799,0.0478897-0.3532944,0.0613632-0.5330963,0.0605087c-0.1878662-0.0008926-0.3736115,0.0139008-0.5577164,0.0524292
						c-0.1148911,0.0240479-0.2318573,0.0331497-0.3490448,0.0267563c-0.164772-0.0089798-0.3262024,0.0130463-0.4885483,0.0373993
						c-0.1771088,0.0265732-0.3554306,0.0501556-0.5340652,0.0604477c-0.4125137,0.0237656-0.8255157,0.0390778-1.2383499,0.0571136
						c-0.3276596,0.0143204-0.654808,0.0034103-0.9814606-0.0237732c-0.1320724-0.0109863-0.2643433-0.0216217-0.3957596-0.0383759
						c-0.4719391-0.060173-0.9454498-0.1038284-1.4197311-0.1397476c-0.1477051-0.0111847-0.2954712-0.0228119-0.4434204-0.0291443
						c-0.1951981-0.0083466-0.388237-0.0318832-0.5807037-0.0644913c-0.1998138-0.033844-0.4002228-0.0643005-0.6006699-0.0942154
						c-0.4088135-0.0610123-0.8148422-0.1362686-1.2180786-0.2274323c-0.1900101-0.0429611-0.3814545-0.079895-0.5729523-0.115799
						c-0.2225342-0.0417252-0.4422607-0.0940247-0.6572266-0.1649857c-0.2150726-0.0710068-0.4351196-0.1214676-0.6561661-0.1694489
						c-0.7852859-0.1704636-1.5596619-0.3797531-2.3154907-0.6549225c-0.1463394-0.0532761-0.2951279-0.1003036-0.4442368-0.145401
						c-0.3881454-0.1173782-0.7696838-0.2534714-1.1478195-0.3993759c-0.2327957-0.0898285-0.4586563-0.1950455-0.6829681-0.3042984
						c-0.2801285-0.1364517-0.5632477-0.2667694-0.8450546-0.399765c-0.2958984-0.1396408-0.593544-0.2757797-0.8868637-0.4206619
						c-0.1252975-0.0618896-0.2417908-0.1417084-0.3618546-0.2140884c-0.0800476-0.0482483-0.1578522-0.1004105-0.2392349-0.1462555
						c-0.3257523-0.1835403-0.652359-0.3655853-0.9789734-0.5475922c-0.1701202-0.0948029-0.3400192-0.1901016-0.5116653-0.2821045
						c-0.2409134-0.1291275-0.4701614-0.2755356-0.6881027-0.4407959c-0.2300491-0.1744308-0.4704971-0.3327332-0.7327118-0.4567413
						c-0.1053314-0.0498123-0.2088242-0.105835-0.3063278-0.169487c-0.3065033-0.2000656-0.6215134-0.3871384-0.9149857-0.6073074
						c-0.1931076-0.1448746-0.3929291-0.2808762-0.5912094-0.4187469c-0.1535187-0.1067429-0.3093872-0.2101135-0.4640732-0.3151932
						c-0.1481705-0.1006622-0.2937851-0.2053528-0.445076-0.3010788c-0.0984268-0.0622711-0.2021255-0.1181183-0.3084717-0.1656189
						c-0.2908096-0.1298981-0.5879211-0.1414337-0.887558-0.028717c-0.0729141,0.02742-0.1454773,0.0560684-0.2168045,0.0873489
						c-0.2218475,0.0972977-0.4506149,0.174469-0.6848984,0.2348328c-0.3549728,0.0914688-0.7052307,0.1984787-1.0521584,0.3163223
						c-0.2815132,0.0956268-0.5698662,0.1606522-0.8635063,0.2043533c-0.1696434,0.0252533-0.3391037,0.051712-0.5086594,0.0775452
						c-0.3784981,0.057663-0.7588997,0.0874023-1.1419868,0.0782547c-0.1402969-0.0033417-0.2806129-0.005928-0.4209137-0.009201
						c-0.180088-0.0042038-0.3578186-0.0269089-0.5310173-0.0768738c-0.1730156-0.0499115-0.3488693-0.0825424-0.5281105-0.0971832
						c-0.387661-0.0316696-0.7457886-0.1637878-1.0871506-0.3384323c-0.2494774-0.1276474-0.4863892-0.2798157-0.702652-0.4606018
						c-0.0537415-0.0449295-0.1087875-0.0893402-0.1564178-0.140358c-0.1918259-0.2054672-0.366848-0.423996-0.5117798-0.6659927
						c-0.1132698-0.1891174-0.1904335-0.3919601-0.2431297-0.6049423c-0.061451-0.2483826-0.0543823-0.4949493,0.0254173-0.7381821
						c0.1077461-0.3284225,0.2649307-0.6323318,0.4698982-0.9100647c0.1221924-0.1655731,0.2807732-0.2894287,0.4677124-0.3790741
						c0.3421555-0.1640701,0.6991196-0.2611084,1.0805359-0.268219c0.2744637-0.0051117,0.5453453,0.0187759,0.8129654,0.0774231
						c0.2207947,0.0483932,0.4405899,0.1013489,0.6606941,0.1528397c0.0455208,0.0106506,0.0907974,0.0226364,0.1356125,0.0359344
						c0.3940048,0.1169357,0.7964554,0.1406021,1.2043228,0.1143723c0.1961823-0.012619,0.3856659-0.0505524,0.5699883-0.1188049
						c0.3011742-0.1115265,0.5866013-0.2519913,0.8459854-0.4433899c0.0814285-0.0600815,0.1655617-0.1171265,0.2520866-0.1695709
						c0.2021523-0.1225128,0.3784904-0.2725143,0.5243073-0.4596863c0.0861168-0.1105347,0.1830597-0.2120895,0.2635231-0.3279037
						c0.0807877-0.116272,0.1470833-0.2390518,0.1956558-0.3710327c0.180069-0.4893265,0.2637825-0.9913254,0.2165833-1.5148926
						c-0.0344849-0.382515-0.0984879-0.7567749-0.2269211-1.1195908c-0.0649986-0.183609-0.1231499-0.3696671-0.1833534-0.5549622
						c-0.0699234-0.2151947-0.1287003-0.4333496-0.1701927-0.6559525c-0.0619888-0.3325958-0.072403-0.6653366-0.0129623-1.0000381
						c0.0632401-0.3561172,0.2213745-0.6628647,0.4674568-0.9254684c0.1502609-0.1603546,0.3204842-0.2954178,0.5147285-0.3998871
						c0.3397217-0.1827164,0.7056351-0.2420807,1.0855331-0.2252884c0.4898529,0.0216599,0.925827,0.1936264,1.3192444,0.4834671
						c0.1959839,0.1443939,0.3690109,0.3111038,0.5260544,0.4960938c0.1616516,0.1904068,0.3158264,0.386055,0.4401627,0.6035385
						c0.0231857,0.0405502,0.0463562,0.0812073,0.067215,0.1229782c0.2446365,0.4898758,0.4369431,0.9991989,0.5657959,1.5318069
						c0.0183105,0.0756912,0.0331116,0.1524277,0.045517,0.2293243c0.0832977,0.5164413,0.1376648,1.035141,0.1348267,1.5593948
						c-0.0014801,0.2732697-0.0158539,0.5448837-0.0421677,0.8169403c-0.0495605,0.5124359-0.075325,1.026123-0.0545197,1.5415497
						c0.012291,0.3043976,0.1025238,0.5813675,0.268837,0.8345947c0.0340805,0.0518951,0.0717773,0.1021118,0.1130371,0.1484528
						c0.0621719,0.0698242,0.1279984,0.1364822,0.1939468,0.2028275c0.1758804,0.1769409,0.3552933,0.3504562,0.5282974,0.5301514
						c0.1634827,0.1698074,0.349472,0.3099899,0.5454102,0.4385452c0.2018738,0.1324463,0.4120331,0.2526703,0.5990829,0.4069595
						c0.0420837,0.0347137,0.0878448,0.0654144,0.1272583,0.1028671c0.2396088,0.2277145,0.5209732,0.3945923,0.8056259,0.5568848
						c0.0203018,0.0115814,0.0403442,0.0238342,0.0614777,0.0337219c0.2842789,0.133049,0.5412369,0.307045,0.7799454,0.5103683
						c0.1131134,0.0963516,0.2465668,0.1631317,0.3772659,0.2329788c0.1510925,0.0807343,0.301506,0.1627502,0.4515228,0.2454605
						c0.0408478,0.022522,0.079834,0.0485992,0.1186142,0.0746307c0.2011414,0.1350327,0.4130249,0.2502365,0.6355972,0.3457565
						c0.2005997,0.0860901,0.3980713,0.1784821,0.5888214,0.2850189c0.0543365,0.0303574,0.110054,0.059082,0.1674728,0.0829468
						c0.3237076,0.1345062,0.6484604,0.2664948,0.9722137,0.4009094c0.316597,0.1314316,0.6331558,0.2630157,0.9483795,0.3976898
						c0.3299179,0.1409454,0.6608429,0.2778015,1.0120621,0.3589706c0.098526,0.0227585,0.1944656,0.0566025,0.2918396,0.0844803
						c0.1498337,0.0429001,0.2999039,0.0849762,0.4498444,0.1275177c0.1274185,0.0361481,0.2551498,0.071228,0.382103,0.1089401
						c0.3365021,0.0999451,0.6766968,0.1845322,1.0217056,0.249527c0.053627,0.0101013,0.1081009,0.0167465,0.160881,0.0301208
						c0.4312286,0.1092682,0.8712234,0.1745224,1.3068314,0.2617493c0.3214035,0.0643539,0.6469727,0.1009521,0.972229,0.1380539
						c0.2017136,0.0230026,0.4049072,0.0175552,0.6075897,0.0101624c0.0857086-0.0031281,0.1711426-0.0105896,0.253746-0.0371933
						c0.0461884-0.0148849,0.0830307-0.04216,0.1125259-0.0797043c0.0253525-0.03228,0.0374451-0.0710449,0.025856-0.1095657
						c-0.0132675-0.0441132-0.0310516-0.088501-0.0557556-0.1270905c-0.0248795-0.0388641-0.0579834-0.0736694-0.0921097-0.1052551
						c-0.2301254-0.2129974-0.4134674-0.4661407-0.6104507-0.7076035c-0.1674957-0.2053223-0.3342514-0.4112701-0.5040207-0.6147003
						c-0.184906-0.2215652-0.3573532-0.4525909-0.5304108-0.6832809c-0.0513763-0.0684814-0.103714-0.1370316-0.1625366-0.1989746
						c-0.1186676-0.1249695-0.2165756-0.265831-0.3194199-0.402916c-0.0981674-0.1308594-0.1901245-0.2664719-0.2904358-0.3956299
						c-0.081665-0.1051407-0.1536026-0.21595-0.2177811-0.3319092c-0.109375-0.1976242-0.2158813-0.3968506-0.3246307-0.5948334
						c-0.0712585-0.1297226-0.1439514-0.2586517-0.2166748-0.387558c-0.2221222-0.3937683-0.4305267-0.7947464-0.61763-1.2062378
						c-0.0643005-0.1414108-0.1181183-0.2888412-0.1626587-0.4377289c-0.1027908-0.3436661-0.2172241-0.6826477-0.3462982-1.0175934
						c-0.1604691-0.4164505-0.2598267-0.8487091-0.295166-1.2952576c-0.0295334-0.3731766-0.0556641-0.7463684-0.0655823-1.1206818
						c-0.0120392-0.4545517,0.028244-0.90345,0.1371994-1.3454361c0.0653-0.2648849,0.1309586-0.529686,0.1979218-0.7941513
						c0.0191116-0.0754929,0.0396805-0.151001,0.0658264-0.2242661c0.1204758-0.3375626,0.2410126-0.6751251,0.3656921-1.0111465
						c0.1089325-0.2935867,0.2667999-0.5628738,0.4280853-0.8297043c0.1735916-0.287178,0.3650284-0.5625534,0.5812683-0.8190765
						c0.1001205-0.1187744,0.2111282-0.22995,0.3272018-0.3333511c0.2153244-0.1918182,0.4336395-0.3808136,0.658577-0.5611801
						c0.3655167-0.2930832,0.758255-0.5466232,1.1722031-0.7660522c0.2068481-0.1096497,0.4251785-0.194191,0.6467743-0.2692719
						c0.2066574-0.0700302,0.4132233-0.1407318,0.6219177-0.204277c0.3888321-0.1184006,0.7847672-0.1998596,1.1915054-0.2297516
						c0.4206924-0.0309143,0.8409195-0.0387573,1.2619095-0.0110626c0.3833313,0.0252151,0.7543335,0.1004105,1.1188049,0.2269897
						c0.4713364,0.1636963,0.93927,0.3353043,1.4019775,0.5215149c0.2821732,0.1135635,0.5578308,0.2404404,0.8216095,0.3930359
						c0.2629852,0.1521378,0.5286255,0.2996979,0.7936249,0.4483261c0.2183609,0.122467,0.4225769,0.2643204,0.6080399,0.4330521
						c0.0518112,0.047142,0.1087494,0.0886078,0.1621552,0.1340637c0.2142639,0.182373,0.4530258,0.3306351,0.6829834,0.4911118
						c0.2242966,0.1565323,0.4381485,0.324379,0.6193466,0.5315704c0.1853638,0.2119522,0.4005814,0.3930893,0.6150589,0.5739212
						C93.0137939,106.7488403,93.1946945,106.9198914,93.3610916,107.1062775z M89.1746445,118.2892151
						c-0.0004654,0.0004959-0.0009384,0.0009918-0.0014038,0.0014877c0.1184692,0.112793,0.2444153,0.2188568,0.3535309,0.340065
						c0.1413879,0.157074,0.3005066,0.2938919,0.4547577,0.4367447c0.2808151,0.2600708,0.5837173,0.4901505,0.9014587,0.7036896
						c0.2817535,0.1893539,0.5867844,0.3207245,0.9087067,0.4180374c0.1344528,0.0406418,0.273468,0.0607605,0.4132996,0.0756531
						c0.3619843,0.0385666,0.6995087-0.0441589,1.0108795-0.222084c0.4794006-0.2739487,0.5711823-0.7343903,0.4791183-1.1156693
						c-0.0163727-0.0678101-0.0411835-0.1339188-0.0662079-0.199234c-0.0618744-0.161499-0.1508865-0.3095245-0.2608337-0.4406738
						c-0.1950912-0.2326965-0.3963013-0.460434-0.6194305-0.6678772c-0.2168198-0.201561-0.4312439-0.4057922-0.6430206-0.612648
						c-0.1783524-0.1742096-0.3466568-0.3588715-0.5277176-0.5301208c-0.4305038-0.4071884-0.83284-0.842041-1.2508469-1.261467
						c-0.0384445-0.0385818-0.0733566-0.0806961-0.1098404-0.1212387c-0.0990143-0.1100235-0.1942825-0.2237167-0.2977905-0.3293381
						c-0.1915894-0.1954956-0.3573074-0.4115829-0.5178528-0.6320419c-0.1329498-0.1825638-0.2626343-0.3675003-0.39534-0.5502472
						c-0.1739502-0.2395248-0.3446579-0.481369-0.4933167-0.7375183c-0.1367645-0.2356491-0.2669144-0.4751358-0.4004288-0.7126846
						c-0.1450348-0.2580719-0.2941666-0.5131836-0.4814529-0.7443771c-0.1498795-0.1850128-0.3305206-0.3327713-0.5424042-0.4355011
						c-0.4085312-0.1980667-0.8244095-0.210495-1.2403793-0.0161972c-0.283783,0.1325531-0.471756,0.3489914-0.5707321,0.646019
						c-0.0780411,0.234169-0.1018448,0.4724197-0.0550842,0.7144547c0.0250549,0.129631,0.0575027,0.2590027,0.1004105,0.3837509
						c0.0835648,0.2429276,0.1700897,0.4853058,0.2681503,0.7226334c0.0831375,0.2011948,0.1751404,0.3999176,0.2792664,0.5909882
						c0.2309875,0.423851,0.4718857,0.8422318,0.7216187,1.2555466c0.2589569,0.4285812,0.5649872,0.8201141,0.9017639,1.1896057
						c0.2098923,0.230278,0.4143906,0.4656525,0.6162338,0.7030563c0.2726364,0.3206482,0.5474777,0.6389542,0.849823,0.9324417
						C89.0322647,118.1450195,89.1030121,118.2175827,89.1746445,118.2892151z"
              />
              <path
                d="M85.1385345,59.811554c0.0767746,0.0748405,0.1968079,0.1922913,0.3173828,0.3091812
						c0.0383835,0.0372086,0.075943,0.0757828,0.1177139,0.108902c0.2222977,0.1762733,0.4195251,0.3787956,0.6139374,0.584156
						c0.2943497,0.3109283,0.5983658,0.6127052,0.9191513,0.8955231c0.2643738,0.233078,0.5166931,0.4745178,0.7417068,0.7464867
						c0.1704941,0.2060661,0.367981,0.3880424,0.5684052,0.5652771c0.2298203,0.2032318,0.433136,0.4302368,0.6259613,0.66819
						c0.0959702,0.1184273,0.2017975,0.2292824,0.3079071,0.338974c0.1276245,0.1319275,0.2516174,0.2656784,0.3587952,0.4158478
						c0.1596756,0.2237167,0.3270645,0.4419556,0.4922714,0.661705c0.0412598,0.0548859,0.0833282,0.1098633,0.1307678,0.1592865
						c0.2336121,0.2433548,0.430069,0.5160828,0.628952,0.7870712c0.0948868,0.1292801,0.1866837,0.2614517,0.2898407,0.3839111
						c0.2465363,0.2926712,0.4601288,0.6093521,0.683815,0.9187317c0.0671005,0.092804,0.1381989,0.1829529,0.2010803,0.2785187
						c0.1594467,0.2423096,0.3169479,0.485939,0.471962,0.7311096c0.1020279,0.1613617,0.2037354,0.3225937,0.3140259,0.4787064
						c0.0746689,0.1056976,0.1431274,0.2172623,0.2015305,0.3327026c0.1931992,0.381897,0.4102402,0.7500458,0.6279068,1.1180954
						c0.1009903,0.1707535,0.1995163,0.3434982,0.2882767,0.5207977c0.092514,0.1847992,0.1932297,0.3643417,0.3062897,0.5367737
						c0.134758,0.2055511,0.243103,0.4232178,0.3246384,0.6550369c0.045578,0.1296082,0.0906982,0.2600784,0.1476898,0.3848343
						c0.1880646,0.411644,0.313385,0.8443451,0.4382782,1.2773361c0.0338745,0.1174011,0.0611725,0.236702,0.0949478,0.3541336
						c0.0890656,0.3096542,0.1419601,0.6256943,0.167572,0.9463196c0.0170364,0.2132416,0.0301819,0.4268265,0.0424728,0.6404037
						c0.0087891,0.152916-0.0005951,0.3054581-0.0183258,0.4575729c-0.0212326,0.1821365-0.0440445,0.3641586-0.0610504,0.5467148
						c-0.0222321,0.2386322-0.0820694,0.4673538-0.1875763,0.6813126c-0.1181259,0.2395706-0.2438354,0.4756165-0.3729477,0.7095032
						c-0.1115036,0.2020035-0.2567139,0.3806763-0.4281464,0.5343933c-0.3247528,0.2911911-0.6657028,0.5612411-1.0576477,0.7596054
						c-0.0749283,0.0379257-0.1498642,0.0759201-0.2237625,0.1157913c-0.1285782,0.0693665-0.2647324,0.118042-0.4057922,0.1545563
						c-0.162674,0.0421066-0.3252792,0.0845261-0.4874573,0.1285095c-0.1626358,0.0441055-0.3274841,0.0759277-0.4950714,0.0925903
						c-0.5406799,0.053772-1.0819168,0.0816193-1.6247787,0.0334473c-0.1065369-0.0094528-0.2134399-0.0146713-0.3201523-0.0222778
						c-0.2298126-0.0163651-0.4543228-0.060257-0.6722565-0.1349869c-0.2459717-0.084343-0.4974136-0.147316-0.7497025-0.2098923
						c-0.5648346-0.1400909-1.1140518-0.3235779-1.621788-0.6172333c-0.1582336-0.0915222-0.3262939-0.1664429-0.491806-0.2448502
						c-0.193306-0.0915909-0.3841019-0.1869965-0.5683823-0.2963181c-0.1903458-0.112915-0.3840256-0.2206879-0.5800934-0.3233643
						c-0.4675217-0.2448273-0.9129715-0.5245895-1.3473892-0.8237915c-0.2579346-0.1776581-0.5132751-0.3585587-0.7650223-0.5447083
						c-0.0429688-0.0317764-0.0860596-0.0635452-0.127182-0.0976334c-0.2353439-0.1950912-0.480278-0.377594-0.7263031-0.5588303
						c-0.1784592-0.1314774-0.3490677-0.2721786-0.5068207-0.4289703c-0.2384262-0.2369766-0.4779892-0.4730835-0.724411-0.7016373
						c-0.1678391-0.1556625-0.3466797-0.2996674-0.5234833-0.4453888c-0.1414261-0.1165619-0.2883453-0.2264328-0.4307327-0.3418579
						c-0.1720886-0.1395035-0.3413391-0.2825012-0.5130234-0.4225082c-0.1183319-0.0964966-0.2413635-0.1873856-0.3572769-0.286644
						c-0.0810089-0.0693512-0.1568146-0.1455841-0.2292023-0.2240372c-0.1556396-0.1687164-0.3205719-0.3275146-0.4910583-0.4809799
						c-0.056572-0.0509186-0.1102829-0.105484-0.1613617-0.1619492c-0.1486664-0.1643219-0.295723-0.3301086-0.442688-0.4959564
						c-0.1420364-0.1602783-0.293457-0.310997-0.4516907-0.4553223c-0.0845947-0.0771713-0.1667099-0.1576691-0.2441177-0.2420044
						c-0.3047943-0.3320389-0.6072693-0.666214-0.910614-0.9995804c-0.0411453-0.0452194-0.0822754-0.0904388-0.1235886-0.135498
						c-0.2684784-0.292778-0.5371857-0.58535-0.8053207-0.8784485c-0.0412216-0.0450592-0.0825119-0.0904922-0.1191864-0.1392059
						c-0.1472702-0.1956406-0.3074036-0.3801117-0.4765701-0.5569382c-0.0105591-0.0110321-0.0198059-0.0233307-0.0305252-0.0342026
						c-0.3175125-0.3218842-0.586937-0.6830978-0.8585358-1.0429306c-0.1149368-0.1522751-0.2386475-0.2984161-0.3652649-0.4412384
						c-0.1171646-0.1321564-0.2189026-0.27491-0.3138504-0.4226227c-0.144783-0.2252884-0.3030624-0.4401093-0.474678-0.645607
						c-0.0586166-0.0701904-0.1112823-0.145607-0.1634827-0.2208481c-0.2001419-0.288475-0.4206848-0.5627747-0.599617-0.8660278
						c-0.1668396-0.2827606-0.3362198-0.5640259-0.5033188-0.8466339c-0.1205368-0.2038536-0.2333679-0.4118652-0.3276978-0.629406
						c-0.0609741-0.1406364-0.13414-0.2755165-0.2222519-0.3999062c-0.1649857-0.2329102-0.2806854-0.489666-0.3849106-0.7527237
						c-0.0393677-0.099369-0.0743256-0.200531-0.1096191-0.3014565c-0.0983047-0.2811203-0.1965866-0.5621109-0.3113937-0.8372307
						c-0.0824585-0.1975899-0.1444931-0.4024658-0.1911163-0.6116943c-0.0365524-0.1640434-0.0718842-0.3283691-0.1093826-0.4921989
						c-0.0599213-0.2617188-0.0887146-0.5268936-0.0968018-0.7950401c-0.0057449-0.1908607-0.0133514-0.3818474-0.0276108-0.572216
						c-0.0207672-0.2773552,0.0035934-0.5493126,0.0770721-0.8173103c0.0343323-0.1252327,0.0669327-0.2509384,0.1008911-0.3762779
						c0.0401077-0.1480522,0.0958252-0.2893753,0.1727142-0.4228935c0.15242-0.2646828,0.2986298-0.5327492,0.4333344-0.8070679
						c0.134201-0.2732849,0.3415909-0.4861374,0.5736008-0.6765709c0.0943527-0.077446,0.1956787-0.1463623,0.2925873-0.2207756
						c0.0665894-0.0511322,0.1334-0.1024055,0.1960373-0.1581879c0.288765-0.2571678,0.6133804-0.4529877,0.9787674-0.5808525
						c0.1801834-0.0630531,0.3598785-0.1275024,0.5395813-0.1919441c0.4441376-0.1592712,0.9047699-0.2164345,1.3736801-0.2224693
						c0.0458374-0.0005875,0.091774-0.001339,0.1375275,0.0008354c0.4808197,0.0228462,0.9615326,0.0473061,1.440834,0.0936928
						c0.2289505,0.0221558,0.4525223,0.0687599,0.673233,0.1336594c0.2417908,0.0710907,0.4846039,0.1392632,0.7288666,0.2012291
						c0.2450485,0.0621605,0.4838257,0.1416855,0.716362,0.2395897c0.2392578,0.1007347,0.477272,0.2045593,0.7143097,0.3104248
						c0.2859955,0.127739,0.5652466,0.2686691,0.8340454,0.4303398c0.1504288,0.0904808,0.3071289,0.1705093,0.4581299,0.2600975
						c0.1509247,0.0895576,0.2993164,0.1835251,0.4467773,0.2787247c0.0897522,0.0579453,0.1726608,0.1267738,0.2638321,0.1821823
						c0.2352219,0.1429596,0.4572372,0.3052254,0.6840744,0.4604073c0.0944901,0.0646439,0.1859589,0.1340027,0.2760086,0.2047653
						c0.2041321,0.1603851,0.4135895,0.3140488,0.6026764,0.4932442c0.2054214,0.1946564,0.4271698,0.37043,0.6570969,0.5349503
						c0.3111954,0.2226715,0.5985184,0.4742737,0.890213,0.7207108c0.2042618,0.1725731,0.4087296,0.3449059,0.6118851,0.5187759
						c0.0753632,0.0644951,0.151741,0.1288071,0.2196884,0.2007332
						C84.8374634,59.5532455,84.9597931,59.6678429,85.1385345,59.811554z M81.331192,61.2874908
						c0.0013733-0.0012932,0.0027313-0.0025787,0.0041046-0.0038719c-0.091835-0.091835-0.1824799-0.1848869-0.2757416-0.2752495
						c-0.2249603-0.2179947-0.45047-0.4354362-0.6770477-0.6517563c-0.1934357-0.1846657-0.385788-0.3706322-0.5841064-0.5499496
						c-0.1302109-0.1177368-0.2648697-0.2319717-0.4067154-0.3351555c-0.5197296-0.3780556-1.0657654-0.7132797-1.644104-0.9954147
						c-0.380043-0.1854057-0.7824478-0.2544899-1.2009811-0.2436485c-0.3600693,0.0093231-0.6663361,0.1404305-0.920166,0.4014282
						c-0.148468,0.1526642-0.250145,0.3260117-0.300766,0.5310249c-0.0727539,0.2947006-0.0813446,0.5900993,0.0000534,0.8843002
						c0.1105118,0.3994484,0.266716,0.7798538,0.4815826,1.135376c0.2016068,0.3335838,0.4156189,0.6586304,0.653923,0.9674301
						c0.186676,0.2418938,0.3679657,0.4879341,0.5830002,0.706646c0.3535995,0.3596268,0.7046585,0.7217445,1.0578537,1.0817719
						c0.1178436,0.1201172,0.2316666,0.2429276,0.3341675,0.3770676c0.1206436,0.1578903,0.2528,0.3065033,0.3991547,0.4418411
						c0.1795273,0.1660004,0.3580475,0.3330688,0.5374985,0.4991455c0.0616684,0.0570679,0.12323,0.1144333,0.1875534,0.1684341
						c0.2106628,0.1768265,0.4225235,0.3522415,0.6340866,0.5279922c0.0234985,0.019516,0.0475464,0.0384598,0.0722046,0.0564804
						c0.1541595,0.1126633,0.3071518,0.227005,0.4634018,0.3366928c0.0998688,0.0701294,0.2004623,0.1411819,0.3080292,0.1980057
						c0.337822,0.1784439,0.6835022,0.3405533,1.0427322,0.4722214c0.1726074,0.0632629,0.3493423,0.1107712,0.5306702,0.1416321
						c0.1758423,0.0299301,0.3502808,0.0234375,0.5233917-0.0177841c0.1791687-0.0426636,0.3510742-0.1063232,0.5143661-0.1910858
						c0.1438446-0.0746689,0.2708359-0.171608,0.3681946-0.3036728c0.1589279-0.2155838,0.2443542-0.4562988,0.2489929-0.7248383
						c0.0029144-0.1689224-0.0134354-0.3359833-0.0521011-0.500412c-0.0262299-0.1115265-0.0558777-0.2222519-0.0840912-0.333313
						c-0.0795975-0.3133774-0.2067032-0.6070175-0.3738785-0.8829498c-0.1186981-0.1959076-0.2428055-0.3885536-0.3650131-0.5823288
						c-0.0244141-0.0387154-0.0485077-0.0781174-0.0772629-0.1135406c-0.1781616-0.2194595-0.3503036-0.444397-0.5383224-0.6551285
						c-0.28479-0.3191872-0.5680313-0.6399689-0.8685684-0.9450951
						C81.7090225,61.7084923,81.5228043,61.4953308,81.331192,61.2874908z M88.1774368,72.8395767
						c-0.1729889-0.1904678-0.3792419-0.3460236-0.609375-0.4696732c-0.0668945-0.0359421-0.1368561-0.0675812-0.2081909-0.0935745
						c-0.238121-0.0867844-0.479454-0.0840836-0.7186203-0.0071793c-0.3004837,0.0966339-0.4625626,0.3099136-0.4958191,0.620491
						c-0.0080338,0.0750275-0.0028152,0.1525955,0.0052414,0.2280426c0.0332336,0.3115158,0.1570663,0.5868301,0.3437653,0.8355942
						c0.1377792,0.1835709,0.2935486,0.3512192,0.4612503,0.5073547c0.2567368,0.2390289,0.5568085,0.3972702,0.900322,0.4721069
						c0.2436218,0.0530777,0.4670715,0.0045471,0.6800766-0.1214218c0.1616135-0.0955811,0.2558517-0.2358932,0.3029556-0.4121552
						c0.0401764-0.1503448,0.0361023-0.3018417,0.0037079-0.4533386c-0.0272827-0.1275711-0.0725098-0.2489548-0.1291885-0.3657684
						C88.579277,73.3032608,88.407814,73.0527725,88.1774368,72.8395767z"
              />
              <path
                d="M60.7868958,87.3894424c-0.3093338-0.3415222-0.5442238-0.6020508-0.7806206-0.8612137
						c-0.089283-0.0978851-0.183239-0.1914902-0.2729912-0.2889633c-0.2059059-0.2236099-0.411911-0.4469681-0.6124649-0.6756439
						c-0.2774544-0.3163681-0.5685349-0.6207581-0.8525391-0.9314041c-0.2524567-0.2761459-0.5091209-0.5487518-0.7529831-0.8323364
						c-0.2183304-0.2538986-0.4263802-0.516777-0.6256332-0.7865677c-0.1437187-0.1945953-0.2781868-0.3945618-0.4038162-0.6010742
						c-0.0525589-0.0864029-0.1088715-0.1715622-0.1724739-0.2499924c-0.1474838-0.1818695-0.2787857-0.3751602-0.413002-0.5664749
						c-0.1476364-0.2104416-0.2977333-0.4192581-0.4407539-0.6327972c-0.1516838-0.2264862-0.2939224-0.4591827-0.4524727-0.6812668
						c-0.0586586-0.0821686-0.1094856-0.1705246-0.1578102-0.2593613c-0.1265259-0.2326202-0.252224-0.4657593-0.3731956-0.7012863
						c-0.0639801-0.1245728-0.1228447-0.2524033-0.1746635-0.3824921c-0.2393036-0.6007538-0.479805-1.2011719-0.6665039-1.8212128
						c-0.0044937-0.0149231-0.0099945-0.0295944-0.0136948-0.0447006c-0.1168442-0.4770355-0.2326012-0.9541779-0.2391624-1.4495239
						c-0.001339-0.1012955-0.0073853-0.2025604-0.0130043-0.3037491c-0.0187073-0.336853,0.009552-0.6698227,0.0814095-0.9991989
						c0.0282402-0.1294479,0.0591431-0.258316,0.0870171-0.3878326c0.083149-0.3863831,0.248127-0.7367325,0.4648285-1.0648422
						c0.1853638-0.2806625,0.3994102-0.538147,0.6332436-0.779007c0.1030235-0.1061172,0.2129822-0.2050629,0.3400574-0.284256
						c0.1056976-0.0658646,0.2051468-0.1416626,0.3087845-0.2109451c0.1231422-0.0823059,0.2487221-0.1614532,0.3840446-0.2220764
						c0.1989784-0.0891418,0.3995476-0.1748199,0.6006165-0.2591629c0.1225052-0.0513916,0.2509727-0.0843735,0.381237-0.1102066
						c0.1990738-0.0394745,0.3995171-0.0696564,0.602314-0.0794601c0.4601059-0.0222397,0.918663-0.0176086,1.3767395,0.046875
						c0.4186058,0.0589218,0.8278656,0.1528244,1.2268677,0.2893677c0.4053154,0.1387024,0.8132133,0.2711792,1.206707,0.4422302
						c0.2857437,0.1242142,0.5708046,0.2500381,0.8551216,0.3774872c0.0780602,0.0349884,0.1545258,0.0747452,0.2279854,0.1185684
						c0.3544922,0.2115021,0.7089691,0.4230728,1.0610542,0.6385345c0.4251595,0.2601929,0.8306046,0.5516434,1.2647324,0.7978516
						c0.1763611,0.1000214,0.3415527,0.2175064,0.5049667,0.3374557c0.2198105,0.1613388,0.4538879,0.3024979,0.6678696,0.4721909
						c0.1587296,0.1258774,0.318634,0.250267,0.4778671,0.3755188c0.2633438,0.2071457,0.527298,0.4135208,0.7893448,0.6222992
						c0.0730133,0.0581665,0.1445999,0.1194,0.2092972,0.1865005c0.2387543,0.2476654,0.4994736,0.4704895,0.7719498,0.6789932
						c0.1991043,0.152359,0.3760529,0.3272705,0.5520554,0.5039368c0.1265335,0.1270065,0.2495804,0.2575073,0.3771362,0.3834686
						c0.1552887,0.1533585,0.3132706,0.303627,0.4878693,0.4360199c0.1307602,0.099144,0.2436829,0.2187042,0.3511734,0.3423767
						c0.123024,0.1415176,0.2585068,0.269516,0.3979721,0.3946686c0.2552185,0.2290115,0.509697,0.4592514,0.7557373,0.6979904
						c0.3637848,0.3530045,0.7063293,0.7264709,1.0315857,1.1154404c0.1151428,0.1377106,0.2362213,0.2693634,0.3652725,0.3943176
						c0.319191,0.3090515,0.6416626,0.6151886,0.9398346,0.9449005c0.2561188,0.2832108,0.5046005,0.5733185,0.7570877,0.8598251
						c0.1600494,0.1816101,0.3026581,0.3759537,0.4379196,0.5766525c0.0782166,0.1160583,0.1650848,0.2276077,0.2585144,0.3318253
						c0.2134933,0.2381668,0.4169693,0.4843216,0.6138916,0.7361526c0.0287628,0.0367813,0.0601654,0.0717392,0.0926208,0.1053467
						c0.1140442,0.1181107,0.2136917,0.2474747,0.3079529,0.3816757c0.1522141,0.2167282,0.3040314,0.433876,0.4620819,0.6463394
						c0.1073837,0.1443558,0.2040024,0.2952271,0.288414,0.453064c0.191246,0.3576202,0.4009552,0.7036591,0.6263428,1.0406418
						c0.3162231,0.4727936,0.6125946,0.9578781,0.9021606,1.4475098c0.3138885,0.5307388,0.5919952,1.0787582,0.8351822,1.6449432
						c0.0954132,0.2221375,0.1765213,0.449585,0.2502289,0.6796875c0.0523834,0.163559,0.0919342,0.3302841,0.1190643,0.4999466
						c0.084137,0.5261688,0.0980911,1.054924,0.0581589,1.5855789c-0.0052643,0.0699158-0.0095673,0.1405563-0.023201,0.2090912
						c-0.1220016,0.6137009-0.2825012,1.2145844-0.6023407,1.7606125c-0.1868515,0.318985-0.4133377,0.6048431-0.7129059,0.8258972
						c-0.0873489,0.0644608-0.1836777,0.1170349-0.2773666,0.172554c-0.1339951,0.0794067-0.2698364,0.1556778-0.4040298,0.2347488
						c-0.2624435,0.1546555-0.534729,0.2871475-0.8236008,0.3862991c-0.6316223,0.2167969-0.7799225,0.2530975-1.4341202,0.3099136
						c-0.3656464,0.0317612-0.7322159,0.0289307-1.0979919-0.0020523c-0.1863785-0.0157852-0.3721924-0.038559-0.5580978-0.0595551
						c-0.1787643-0.0202026-0.3527679-0.0609894-0.5232391-0.119194c-0.1252899-0.0427704-0.2516785-0.0832062-0.3795166-0.1174393
						c-0.2110748-0.0565338-0.4185486-0.1231003-0.6218338-0.2030411c-0.0652466-0.0256653-0.1301117-0.0525894-0.196373-0.0753708
						c-0.5413818-0.1861267-1.0393143-0.4588242-1.5232849-0.7600937c-0.1388092-0.0864029-0.2785797-0.1719818-0.4226532-0.2490845
						c-0.3650436-0.1953506-0.7066422-0.426712-1.0420151-0.6678162c-0.2340546-0.1682739-0.4653778-0.3403397-0.6983948-0.5100632
						c-0.0503311-0.0366592-0.1026382-0.07061-0.1541138-0.1056976c-0.1545792-0.1053314-0.3044586-0.2160034-0.4476471-0.3373337
						c-0.2019958-0.1711655-0.4086609-0.337944-0.6232834-0.4927979c-0.3231812-0.2331924-0.6252289-0.4906464-0.9173508-0.7604218
						c-0.2348785-0.2169113-0.4732285-0.4296875-0.7234802-0.6287384c-0.1341553-0.1067047-0.267128-0.2154312-0.4068146-0.314537
						c-0.3191299-0.2264023-0.6123047-0.4816971-0.8790054-0.7675705c-0.3034019-0.3252106-0.630249-0.6262817-0.956604-0.9276657
						c-0.3380814-0.3122101-0.6648712-0.635437-0.9802666-0.9704971c-0.1227722-0.1304321-0.2514648-0.2553101-0.3780136-0.382164
						C61.1391983,87.7413712,60.9240837,87.5266266,60.7868958,87.3894424z M61.6705208,81.2061768
						c0.3555489,0.4234543,0.824482,0.7889252,1.2622452,1.1874542c0.0229912,0.0209274,0.0490685,0.0384445,0.0735168,0.0577927
						c0.2810478,0.2224503,0.5612297,0.4460068,0.8433456,0.667099c0.2023048,0.1585464,0.4029121,0.3193741,0.6232719,0.4536438
						c0.0996323,0.0606995,0.1927032,0.1321106,0.2892914,0.1978683c0.1868896,0.1272278,0.3772278,0.2485809,0.5823822,0.3451691
						c0.1409302,0.0663528,0.2819672,0.132637,0.4210434,0.2027283c0.3533783,0.1780777,0.7317581,0.2603989,1.1217194,0.304924
						c0.3764114,0.042984,0.6871948-0.0950089,0.9610672-0.3370285c0.0516281-0.0456238,0.0930939-0.1047058,0.1318893-0.1625137
						c0.1210556-0.1803665,0.1661301-0.3841934,0.1743546-0.5980225c0.0063171-0.1646042-0.0145416-0.3265915-0.0468903-0.4876404
						c-0.0949936-0.4729919-0.2910385-0.9028549-0.5569458-1.3024521c-0.0733261-0.110199-0.1558228-0.2143021-0.2289658-0.3246155
						c-0.1032639-0.1557617-0.2012558-0.3150101-0.3023605-0.4722137c-0.2319336-0.3606033-0.4836578-0.7075348-0.7380219-1.052269
						c-0.1525497-0.2067337-0.31353-0.4073563-0.5067596-0.5801926c-0.2270355-0.2030792-0.4279556-0.4312057-0.6210251-0.6666412
						c-0.1833038-0.2235184-0.3850479-0.4288483-0.6130371-0.6081696c-0.0916748-0.0720978-0.179039-0.1506119-0.2620087-0.2326355
						c-0.2161865-0.2136841-0.4297829-0.4300308-0.6427078-0.6469803c-0.2899742-0.2954636-0.6008148-0.5659485-0.9359207-0.8096924
						c-0.2267036-0.1648941-0.4577255-0.3240891-0.6735649-0.5041351c-0.1132927-0.0945053-0.232296-0.1836548-0.3622475-0.256134
						c-0.2584686-0.144165-0.5140076-0.2938156-0.7755051-0.4322281c-0.1931725-0.1022491-0.3995247-0.1740799-0.6131477-0.2241669
						c-0.352478-0.0826492-0.6963844-0.0569839-1.0329933,0.0749893c-0.1921272,0.075325-0.3600082,0.1857224-0.4911499,0.3464432
						c-0.1390152,0.170372-0.2481003,0.358017-0.3184547,0.5675888c-0.0920448,0.2741928-0.1051254,0.5502243-0.0188866,0.8274536
						c0.0555267,0.1785049,0.113842,0.3563461,0.1781311,0.5318375c0.0346603,0.0946198,0.0807495,0.1856995,0.1285133,0.2747116
						c0.0551834,0.1028366,0.1145248,0.2038116,0.1779442,0.3017883c0.2116165,0.3269424,0.4261169,0.6520233,0.6390114,0.9781418
						c0.140892,0.2158127,0.297554,0.4188919,0.4732246,0.607933c0.2228165,0.2397842,0.4417229,0.4832001,0.6620331,0.7253036
						c0.0419617,0.0461197,0.0851021,0.0914917,0.1234283,0.1405411c0.1590004,0.2034607,0.3429184,0.382988,0.5294075,0.5604858
						C61.4329643,80.964386,61.5355911,81.0712433,61.6705208,81.2061768z M71.6234665,89.7534332
						c-0.1272659-0.1287231-0.2991409-0.2855072-0.4946899-0.4169006c-0.2401047-0.1613388-0.4931717-0.2974854-0.7555008-0.4175491
						c-0.0494614-0.022644-0.100502-0.0428467-0.1524353-0.0589066c-0.5177155-0.1601028-0.9937286,0.2075577-1.0349731,0.6868057
						c-0.0163269,0.1897125,0.0055084,0.373764,0.0736313,0.5512161c0.154213,0.40168,0.3989105,0.7376785,0.7349701,1.0072784
						c0.0971222,0.077919,0.1956635,0.1551132,0.2848434,0.2416229c0.134903,0.1308517,0.2842789,0.243187,0.4341583,0.3546295
						c0.1061096,0.0789032,0.2207108,0.1463699,0.345787,0.1932602c0.2278824,0.0854187,0.4634399,0.1348724,0.7061539,0.1501389
						c0.1020279,0.0064163,0.2028809-0.0017624,0.2994385-0.0375595c0.1115646-0.0413666,0.2064056-0.1045532,0.2783966-0.2031021
						c0.1112137-0.1522598,0.1476822-0.3237762,0.1437683-0.5072861c-0.0030289-0.1419907-0.03759-0.277626-0.0903625-0.4086456
						c-0.0699463-0.1736526-0.1521835-0.3417969-0.2471619-0.5028076c-0.0711746-0.1206589-0.1521378-0.2354126-0.2509079-0.3366776
						C71.8169861,89.9653015,71.7391129,89.8780212,71.6234665,89.7534332z"
              />
            </g>
          </g>
        </motion.g>
      </motion.svg>
    </div>
  );
}
